<template>
  <div class="row">
    <div class="col-12 col-md-10">
      <ElForm
        ref="form"
        :model="payloadRef"
        :disabled="!isOwner"
        :rules="rules"
        class="row align-items-center"
        @submit.native.prevent="updateShowingProfileDefaults"
      >
        <div class="col-12">
          <h3 class="font-21">
            Showing profile defaults
          </h3>
          <p class="font-15">
            These values will be used for new showing profiles. Changing them here will not affect any currently showing
            properties
          </p>
        </div>
        <div
          v-if="businessSource && !isLegacyIntegrationEnabled"
          class="col-12 row no-gutters justify-content-between mt-3"
        >
          <h3 class="font-weight-normal">
            Sync application URL from {{ integrationType }}
          </h3>
          <ElFormItem
            prop="sync_application_url"
          >
            <SdSwitchSaved
              :is-saved="uiFlags.syncApplicationUrl"
              @hide-text="hideSwitchText('syncApplicationUrl')"
            >
              <ElSwitch
                v-model="payloadRef.sync_application_url"
                :width="44"
                class="col-auto d-block"
                @change="onToggleChange('sync_application_url', 'syncApplicationUrl')"
              />
            </SdSwitchSaved>
          </ElFormItem>
        </div>
        <div class="col-12 pt-0 mb-3">
          <ElFormItem
            v-if="!payloadRef.sync_application_url"
            prop="default_application_form_url"
            class="mb-3"
            :rules="{ validator: validateApplicationUrl }"
          >
            <SdFloatLabel>
              <ElTooltip
                :visible-arrow="false"
                :disabled="$viewport.lt.md"
                popper-class="popover-panel"
                placement="right-start"
              >
                <template slot="content">
                  <i class="sdicon-info-circle" />
                  <p>This link will be used when enabling properties for showing.</p>
                </template>
                <SdFloatLabel>
                  <ElInput
                    v-model="payloadRef.default_application_form_url"
                    placeholder="Application URL"
                  />
                </SdFloatLabel>
              </ElTooltip>
            </SdFloatLabel>
          </ElFormItem>
          <ElFormItem>
            <SdFloatLabel>
              <ElTooltip
                :visible-arrow="false"
                :disabled="$viewport.lt.md"
                popper-class="popover-panel"
                placement="right-start"
              >
                <template slot="content">
                  <i class="sdicon-info-circle" />
                  <p>Anything you'd like to emphasise to prospects who are about to tour this property</p>
                </template>
                <SdFloatLabel>
                  <ElInput
                    v-model="payloadRef.default_general_notes"
                    type="textarea"
                    :rows="4"
                    placeholder="Notes to prospect"
                  />
                </SdFloatLabel>
              </ElTooltip>
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12">
          <div class="row no-gutters justify-content-between my-1">
            <h3 class="font-weight-normal">
              Schedule notice limit for your team
            </h3>
            <div class="row no-gutters">
              <p class="font-15">
                How long in advance prospects can schedule tours for showing windows where your team members are
                assigned.
              </p>
            </div>
            <div class="col-12 col-md-5">
              <ElFormItem
                prop="schedule_notice_limit"
                :rules="{ validator: validateScheduleNoticeLimit }"
              >
                <SdFloatLabel>
                  <ElInput
                    v-model="payloadRef.schedule_notice_limit"
                    v-no-scroll
                    :disabled="!isOwner"
                    placeholder="Hours in advance"
                    type="number"
                    min="2"
                    max="48"
                    @input="scheduleNoticeLimitChanged"
                  />
                </SdFloatLabel>
              </ElFormItem>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row no-gutters justify-content-between my-1">
            <h3 class="font-weight-normal">
              Schedule notice limit for self-showings
            </h3>
            <div class="row no-gutters">
              <p class="font-15">
                How long in advance prospects can schedule tours for self-showing tours.
              </p>
            </div>
            <div class="col-12 col-md-5">
              <ElFormItem
                prop="self_showing_schedule_notice_limit"
                :rules="{ validator: validateSelfShowingsScheduleNoticeLimit }"
              >
                <SdFloatLabel>
                  <ElInput
                    v-model="payloadRef.self_showing_schedule_notice_limit"
                    v-no-scroll
                    :disabled="!isOwner"
                    placeholder="Hours in advance"
                    type="number"
                    min="0"
                    max="72"
                    @input="selfShowingsScheduleNoticeLimitChanged"
                  />
                </SdFloatLabel>
              </ElFormItem>
            </div>
          </div>
        </div>
        <SelfShowingHours
          :disabled="!isOwner"
          :showing-hours="payloadRef.showing_hours"
          @change="updateShowingHours"
        />
        <div class="col-12 d-flex justify-content-end">
          <ElButton
            v-if="authUser.role === Role.ACCOUNT_OWNER"
            type="primary"
            :size="$viewport.gt.md ? 'medium' : 'small'"
            :loading="uiFlags.showingProfileFormLoading"
            @click="updateShowingProfileDefaults"
          >
            Update
          </ElButton>
        </div>
      </ElForm>
      <div class="row no-gutters">
        <ElDivider class="my-4" />
      </div>
    </div>
    <div class="col-12 col-md-10">
      <h3 class="font-21">
        Pre-screening templates
      </h3>
      <p class="font-15">
        These criteria will be used as a template when enabling properties for showing. Changing them here will not
        affect any currently showing
        properties.
      </p>
      <Draggable
        :list="list"
        class="pt-0 col-12 list-group"
        :disabled="false"
        :move="checkMove"
        :v-model="list"
        @start="dragging = true"
        @end="onDragEnd"
      >
        <TemplateOption
          v-for="element in list"
          :key="element.id"
          :option="element"
          :form-is-opened="uiFlags.createTemplateForm"
          class="list-group-item"
          :class="uiFlags.activeTemplateOptionId === element.id ? 'active' : 'default'"
          @edit-template="id => editTemplate(id)"
          @reorganize-template-list="id => reorganizeTemplateList(id)"
        />
      </Draggable>
      <ElButton
        v-if="!uiFlags.createTemplateForm"
        class="mt-3"
        size="small"
        @click="addScreeningTemplate"
      >
        <i class="sdicon-plus" /> Add template
      </ElButton>
      <CreateTemplateForm
        v-if="uiFlags.createTemplateForm"
        class="mt-5"
        :template-data="presentedTemplateFormData"
        :order="Number(nextTemplateOptionOrder)"
        @template-added="(templatePayload) => updateTemplateList(templatePayload)"
        @discard="discardTemplateChanges"
      />
      <div class="row no-gutters">
        <ElDivider class="my-4" />
      </div>
    </div>
    <div class="col-12 col-md-10">
      <h3 class="font-21">
        Pre-screening for vouchers or subsidies
      </h3>
      <p class="font-15">
        Choose how you would like to set up your pre-screening process to handle housing assistance programs.
        <a
          href="https://help.showdigs.com/kb/guide/en/pre-screening-for-vouchers-or-subsidies-uNamtIfxCx/Steps/3353567"
          target="_blank"
        >Learn more</a>
      </p>
      <SdFloatLabel>
        <ElSelect
          v-model="payload.income_voucher"
          placeholder="Income to rent ratio calculation"
          @change="updateIncomeVoucher"
        >
          <ElOption
            v-for="({label, value}) in IncomeVoucherOptions"
            :key="value"
            :label="label"
            :value="value"
          />
        </ElSelect>
      </SdFloatLabel>
      <div class="row no-gutters">
        <ElDivider class="my-4" />
      </div>
    </div>

    <div class="col-12 col-md-10">
      <BusinessSettingsShowingsAIIdentity />
      <ElDivider class="my-4" />
    </div>

    <div class="col-12 col-md-10">
      <BusinessSettingScheduleTemplates />
      <ElDivider class="my-4" />
    </div>

    <div class="col-12 col-md-10">
      <h3 class="font-21">
        Other showings settings
      </h3>
      <div class="row no-gutters justify-content-between mt-4">
        <h3 class="font-weight-normal">
          Multiple Party Tours
        </h3>
        <SdSwitchSaved
          :is-saved="uiFlags.isConcurrentToursAllowed"
          @hide-text="hideSwitchText('isConcurrentToursAllowed')"
        >
          <ElSwitch
            v-model="payloadRef.is_concurrent_tours_allowed"
            :disabled="!isOwner"
            :width="44"
            class="col-auto d-block"
            @change="onToggleChange('is_concurrent_tours_allowed', 'isConcurrentToursAllowed')"
          />
        </SdSwitchSaved>
      </div>
      <div class="row no-gutters">
        <p class="font-15 mb-2">
          Allow multiple parties to schedule a tour at the same time, up to {{ maximumLeadsPerTourRef }} parties.
        </p>
        <div class="font-15">
          <span
            v-if="payloadRef.is_concurrent_tours_allowed"
            class="text-gray-dark"
          >
            <b>Enabled: </b>Multiple prospects will be able to request a tour in a given showing time. Multiple party tours are only available for
            currently vacant properties.
          </span>
          <span
            v-else
            class="text-gray-dark"
          > <b>Disabled: </b>Only one prospect will be able to schedule a tour for any given showing time. </span>
        </div>
      </div>
      <div class="row no-gutters">
        <ElDivider class="my-4" />
      </div>
    </div>

    <div class="col-12 col-md-10">
      <div class="row no-gutters justify-content-between">
        <h3 class="font-weight-normal">
          Live Video Tours
        </h3>
        <SdSwitchSaved
          :is-saved="uiFlags.videoToursEnabled"
          @hide-text="hideSwitchText('videoToursEnabled')"
        >
          <ElSwitch
            v-model="payloadRef.video_tours_enabled"
            class="col-auto d-block"
            :disabled="!isOwner"
            :width="44"
            @change="onToggleChange('video_tours_enabled', 'videoToursEnabled')"
          />
        </SdSwitchSaved>
      </div>
      <div class="row no-gutters">
        <p class="font-15">
          Allow leads to schedule live video tours. We will connect them with our agents for a video call in which they
          can survey the property.
        </p>
      </div>
      <div class="row no-gutters">
        <ElDivider class="my-4" />
      </div>
    </div>

    <div class="col-12 col-md-10">
      <div class="row no-gutters justify-content-between">
        <h3 class="font-weight-normal">
          Open House Confirmation
        </h3>
        <SdSwitchSaved
          :is-saved="uiFlags.isLeadConfirmationRequiredForOpenHouse"
          @hide-text="hideSwitchText('isLeadConfirmationRequiredForOpenHouse')"
        >
          <ElSwitch
            v-model="payloadRef.is_lead_confirmation_required_for_open_house"
            :disabled="!isOwner"
            :width="44"
            class="col-auto d-block"
            @change="onToggleChange('is_lead_confirmation_required_for_open_house', 'isLeadConfirmationRequiredForOpenHouse')"
          />
        </SdSwitchSaved>
      </div>
      <div class="row no-gutters">
        <p class="font-15 mb-2">
          Require open house participants to confirm their attendance shortly before the open house begins.
        </p>
        <div class="font-15">
          <span
            v-if="payloadRef.is_lead_confirmation_required_for_open_house"
            class="text-gray-dark"
          >
            <b>Enabled: </b>Prospects will receive an SMS shortly before the open house to confirm their attendance. If they do not confirm in time,
            their participation is canceled.
          </span>
          <span
            v-else
            class="text-gray-dark"
          >
            <b>Disabled: </b>After registering to attend, prospects will not have to confirm their attendance prior to the open house.
          </span>
        </div>
      </div>
      <div class="row no-gutters">
        <ElDivider class="my-4" />
      </div>
    </div>

    <div class="col-12 col-md-10">
      <div class="row no-gutters justify-content-between">
        <h3 class="font-weight-normal">
          Basic Lockbox Self-Showings
        </h3>
        <SdSwitchSaved
          :is-saved="uiFlags.basicLockboxSelfShowingsEnabled"
          @hide-text="hideSwitchText('basicLockboxSelfShowingsEnabled')"
        >
          <ElSwitch
            v-model="payloadRef.self_showing_contractor_lockbox_enabled"
            class="col-auto d-block"
            :disabled="!isOwner"
            :width="44"
            @change="toggleSelfShowingLockbox"
          />
        </SdSwitchSaved>
      </div>
      <div class="row no-gutters">
        <p class="font-15">
          <strong class="mr-2">Use at your own risk.</strong>
          <span>This will allow self-showings by providing the lockbox's code for prospects to use to tour your properties.</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import Draggable from 'vuedraggable';
import { getSettingValueByKey } from '@/utils/SettingUtil';
import SdSwitchSaved from '@/components/common/form/SdSwitchSaved';
import SdFloatLabel from '@/components/common/form/SdFloatLabel';
import TemplateOption from '@/components/settings/BusinessSettingsShowings/TemplateOption';
import CreateTemplateForm from '@/components/settings/BusinessSettingsShowings/CreateTemplateForm.vue';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import { IncomeVoucherOptions } from '@/constants/Business';
import BusinessSettingScheduleTemplates
  from '@/components/settings/ScheduleTemplates/BusinessSettingScheduleTemplates.vue';
import BusinessSettingsShowingsAIIdentity
  from '@/components/settings/BusinessSettingsShowings/BusinessSettingsShowingsAIIdentity.vue';
import { getBusinessSourceType } from '@/utils/BusinessSourceUtil';
import { capitalizeFirstLatter } from '@/utils/StringUtil';
import SelfShowingHours from '@/components/settings/Showings/SelfShowingHours.vue';
import Role from '../../constants/Role';
import regex from '../../constants/Regex';

const regexConstants = regex;
const showingProfileDefaultValidFieldsToUpdate = [
  'default_application_form_url',
  'default_general_notes',
  'schedule_notice_limit',
  'self_showing_schedule_notice_limit',
  'sync_application_url',
  'showing_hours',
];

export default {
  name: 'BusinessSettingsShowingsTab',
  components: {
    BusinessSettingsShowingsAIIdentity,
    CreateTemplateForm,
    Draggable,
    BusinessSettingScheduleTemplates,
    SdSwitchSaved,
    SdFloatLabel,
    TemplateOption,
    SelfShowingHours,
  },
  props: {
    authUser: {
      type: Object,
      default: () => {
      },
    },
    payload: {
      type: Object,
      default: () => {
      },
    },
    isOwner: {
      type: Boolean,
      default: () => {
      },
    },
    screeningCriteria: {
      type: Object,
      default: () => {
      },
    },
    rules: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const payloadRef = ref(cloneDeep(props.payload));
    const screeningCriteriaRef = ref(props.screeningCriteria);

    const uiFlags = reactive({
      isConcurrentToursAllowed: false,
      videoToursEnabled: false,
      basicLockboxSelfShowingsEnabled: false,
      preScreeningFormLoading: false,
      showingProfileFormLoading: false,
      isLeadConfirmationRequiredForOpenHouse: false,
      createTemplateForm: false,
      activeTemplateOptionId: null,
      syncApplicationUrl: false,
      restrictSelfShowingHours: payloadRef.value.showing_hours !== null,
    });

    const maximumLeadsPerTourRef = ref(getSettingValueByKey('maximum_concurrent_leads_per_showing', null));
    const businessSource = context.root.$store.getters['BusinessSource/get'];
    const integrationType = capitalizeFirstLatter(getBusinessSourceType());
    const isLegacyIntegrationEnabled = context.root.$store.getters['BusinessSource/isLegacyIntegrationEnabled'];

    watch(
        () => props.payload,
        (newValue) => {
          payloadRef.value = cloneDeep(newValue);
          uiFlags.restrictSelfShowingHours = payloadRef.value.showing_hours !== null;
        },
);

    const screeningTemplates = ref(payloadRef.value.screening_templates.map((value, index) => {
      const additionalData = {};
      if (value.title === 'Disabled' || value.title === 'Custom') {
        additionalData.description = value.title === 'Disabled'
            ? 'Include an option to disable pre-screening for a specific property when starting showings.'
            : 'Include an option to individually set the pre-screening parameters for a specific property, that doesn\'t fit a template.';
        additionalData.action = 'switch';
      }
      return { ...value, ...additionalData };
    }));

    const list = ref(screeningTemplates.value);
    const presentedTemplateFormData = ref({});
    const nextTemplateOptionOrder = ref(findMaxOrderNumber());

    return {
      uiFlags,
      payloadRef,
      screeningCriteriaRef,
      Role,
      maximumLeadsPerTourRef,
      updateDefaultScreeningCriteria,
      validateApplicationUrl,
      validateScheduleNoticeLimit,
      validateSelfShowingsScheduleNoticeLimit,
      updateShowingProfileDefaults,
      hideSwitchText,
      scheduleNoticeLimitChanged,
      selfShowingsScheduleNoticeLimitChanged,
      onToggleChange,
      hasChanges,
      list,
      toggleSelfShowingLockbox,
      dragging: false,
      checkMove,
      onDragEnd,
      addScreeningTemplate,
      updateIncomeVoucher,
      IncomeVoucherOptions,
      editTemplate,
      discardTemplateChanges,
      updateTemplateList,
      presentedTemplateFormData,
      reorganizeTemplateList,
      nextTemplateOptionOrder,
      businessSource,
      integrationType,
      updateShowingHours,
      isLegacyIntegrationEnabled,
    };

    async function onDragEnd() {
      list.value = [...list.value];
      // "🔼👁️"
      const newOrder = list.value.map((templateOption) => ({
        order: list.value.indexOf(templateOption),
        id: templateOption.id,
      }));
      try {
        await store.dispatch('ScreeningTemplates/updateOrder', reorderItemsDynamically(newOrder));
      } catch (error) {
        showErrorNotify(context, error.message);
      }
    }

    function reorderItemsDynamically(items) {
      items.sort((a, b) => b.order - a.order);
      return items.map((item, index) => ({
        ...item,
        order: index,
      }));
    }

    function findMaxOrderNumber() {
      if (!list.value.length) {
        return 0;
      }
      let maxOrderNumber = -Infinity;
      list.value.forEach((templateOption) => {
        if (templateOption?.order > maxOrderNumber) {
          maxOrderNumber = templateOption.order;
        }
      });

      return maxOrderNumber + 1;
    }

    async function editTemplate(templateId) {
      presentedTemplateFormData.value = screeningTemplates.value.find((data, index) => data.id === templateId);
      uiFlags.activeTemplateOptionId = templateId;
      uiFlags.createTemplateForm = true;
      findMaxOrderNumber();
    }

    function reorganizeTemplateList(templateId) {
      list.value = list.value.filter((data, index) => data.id !== templateId);
      screeningTemplates.value = screeningTemplates.value.filter((data, index) => data.id !== templateId);
      findMaxOrderNumber();
    }

    function discardTemplateChanges() {
      uiFlags.createTemplateForm = false;
      presentedTemplateFormData.value = null;
      uiFlags.activeTemplateOptionId = null;
    }

    function updateTemplateList(templatePayload) {
      discardTemplateChanges();
      if (templatePayload.isEdit) {
        const indexToUpdate = screeningTemplates.value.findIndex((data, index) => data.id === templatePayload.payload.id);
        screeningTemplates.value[indexToUpdate] = templatePayload.payload;
        const templateOptionIndex = list.value.findIndex((data, index) => data.id === templatePayload.payload.id);
        list.value[templateOptionIndex].title = templatePayload.payload.title;
        list.value[templateOptionIndex].id = templatePayload.payload.id;
        list.value[templateOptionIndex].action = 'edit';
        list.value[templateOptionIndex].order = templatePayload.payload.order;
        list.value[templateOptionIndex].uuid = templatePayload.payload.uuid;
        return;
      }

      screeningTemplates.value.unshift(templatePayload.payload);
      list.value = screeningTemplates.value;
    }

    function updateShowingHours(showingHours) {
      if (!showingHours?.from && !showingHours?.to) {
        payloadRef.value.showing_hours = null;
        return;
      }

      payloadRef.value.showing_hours = {
        from: showingHours.from || null,
        to: showingHours.to || null,
      };
    }

    function checkMove(e) {
      window.console.log(`Future index: ${e.draggedContext.futureIndex}`);
    }

    function hasChanges(originalPayload) {
      const payloadClone = cloneDeep({ ...payloadRef.value });
      const formPreScreeningCriteria = checkChange(formsFields.preScreeningCriteria, originalPayload, payloadClone);
      const formShowingProfileDefaults = checkChange(formsFields.showingProfileDefaults, originalPayload, payloadClone);

      return formPreScreeningCriteria || formShowingProfileDefaults;
    }

    function checkChange(formFields, originalPayload, payloadClone) {
      for (const name of formFields) {
        const originalValue = get(originalPayload, name);
        const currentValue = get(payloadClone, name);
        if (!isEqual(originalValue, currentValue) && !(originalValue === null && currentValue === '')) {
          return true;
        }
      }
      return false;
    }

    async function updateShowingProfileDefaults() {
      uiFlags.showingProfileFormLoading = true;
      try {
        await context.refs.form.validate();

        const showingProfileDefaultsFields = {};
        for (const key of showingProfileDefaultValidFieldsToUpdate) {
          showingProfileDefaultsFields[key] = payloadRef.value[key];
        }

        await updatePayload('showing_profile_default', showingProfileDefaultsFields);
        showSuccessNotify(context, 'Showing profile defaults were updated');
      } finally {
        uiFlags.showingProfileFormLoading = false;
      }
    }

    function addScreeningTemplate() {
      uiFlags.createTemplateForm = true;
    }

    async function updateDefaultScreeningCriteria(screeningCriteria) {
      payloadRef.value.default_screening_criteria = screeningCriteria;
    }

    function validateApplicationUrl(rule, value, callback) {
      if (!value) {
        return callback();
      }
      if (!value.match(regexConstants.url)) {
        return callback(new Error('Application URL is not valid'));
      }
      if (!value.match(regexConstants.http_protocol)) {
        payloadRef.value.default_application_form_url = `https://${value}`;
      }
      return callback();
    }

    function validateScheduleNoticeLimit(rule, value, callback) {
      if (!value) {
        return callback('Schedule notice limit for your team cannot be empty');
      }
      if (!(value >= 2 && value <= 48)) {
        return callback(new Error('Schedule notice limit for your team must be between 2 and 48 hours'));
      }
      return callback();
    }

    function validateSelfShowingsScheduleNoticeLimit(rule, value, callback) {
      if (value === null) {
        return callback('Schedule notice limit for self-showings cannot be empty');
      }
      if (!(value >= 0 && value <= 72)) {
        return callback(new Error('Schedule notice limit for self-showings must be between 0 and 72 hours'));
      }
      return callback();
    }

    function hideSwitchText(key) {
      uiFlags[key] = false;
    }

    async function onToggleChange(key, uiFlagKey) {
      const params = {};
      params[key] = payloadRef.value[key];
      await updatePayload(key, params);
      uiFlags[uiFlagKey] = true;
    }

    async function toggleSelfShowingLockbox() {
      try {
        await onToggleChange('self_showing_contractor_lockbox_enabled', 'basicLockboxSelfShowingsEnabled');
      } catch (error) {
        if (error) {
          payloadRef.value.self_showing_contractor_lockbox_enabled = !payloadRef.value.self_showing_contractor_lockbox_enabled;
          uiFlags.basicLockboxSelfShowingsEnabled = !uiFlags.basicLockboxSelfShowingsEnabled;
        }
      }
    }

    function scheduleNoticeLimitChanged() {
      payloadRef.value.schedule_notice_limit = payloadRef.value.schedule_notice_limit ? Number(payloadRef.value.schedule_notice_limit) : undefined;
    }

    function selfShowingsScheduleNoticeLimitChanged() {
      payloadRef.value.self_showing_schedule_notice_limit = payloadRef.value.self_showing_schedule_notice_limit ?? 0;
    }

    async function updateIncomeVoucher(value) {
      await updatePayload(
          'income_voucher',
          { income_voucher: value },
          'Pre-screening for vouchers or subsidies was updated',
      );
    }

    async function updatePayload(meta, payload, successMessage = null) {
      try {
        await store.dispatch('BusinessSettings/updateAndCommitPayload', {
          meta,
          payload,
        });

        if (successMessage) {
          showSuccessNotify(context, successMessage);
        }
      } catch (error) {
        if (!(error instanceof Error)) {
          showErrorNotify(context, 'A section in screen is incomplete, please check all required fields');
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
        throw error;
      }
    }
  },
};

const formsFields = {
  preScreeningCriteria: [
    'default_screening_criteria.smoking_policy',
    'default_screening_criteria.pet_policy',
    'default_screening_criteria.minimum_credit_score',
    'default_screening_criteria.minimum_income_rent_ratio',
    'default_screening_criteria.eviction_history',
  ],
  showingProfileDefaults: ['default_application_form_url', 'default_general_notes', 'schedule_notice_limit', 'sync_application_url'],
};
</script>
<style lang="scss" scoped>
.active {
  background-color: #ebf4ff;
  border: 1px solid #DFE5EB;
  border-left: 2px solid transparent;
  border-left-color: #3790FF;
  border-right: none;
}

.default {
  border-bottom: 1px solid transparent;
  border-bottom-color: #DFE5EB;
}
</style>
