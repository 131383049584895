<template>
  <div class="row">
    <div class="col-12 col-md-10">
      <div class="row no-gutters d-block">
        <div class="col-12">
          <h3 class="font-21">
            Team
          </h3>
        </div>
        <div class="row">
          <TeamMembersTable
            class="mt-1"
            :class="$viewport.gt.md ? 'col-12' : ''"
          />
        </div>
      </div>
      <div class="row no-gutters">
        <ElDivider class="mt-0 mb-0" />
      </div>
    </div>

    <div class="col-12 col-md-10">
      <div class="row no-gutters">
        <BusinessListingsSnippets
          :class="$viewport.gt.md ? '' : 'col-12'"
          :business="business"
          @toggle-listing-apply-button="updatePayloadListingApplyButton"
        />
      </div>
      <div class="row no-gutters">
        <ElDivider class="mt-4 mb-0" />
      </div>
    </div>

    <div class="col-12 col-md-10">
      <div class="row no-gutters">
        <div class="col-12">
          <h3 class="font-21">
            Business Details
          </h3>
        </div>
      </div>
      <p class="font-15 text-gray-dark">
        These items can be modified only by the account owner.
      </p>
      <ElForm
        ref="form"
        :model="payloadRef"
        :disabled="!isOwner"
        :rules="rules"
        class="row align-items-center"
        @submit.native.prevent="updateBusinessDetails"
      >
        <div class="col-12 col-md-6">
          <SdFloatLabel>
            <ElFormItem
              prop="name"
              :rules="{ validator: validateBusinessName }"
            >
              <ElInput
                v-model="payloadRef.name"
                :disabled="!isOwner"
                placeholder="Business name"
              />
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div class="col-12 col-md-6">
          <SdFloatLabel>
            <ElFormItem prop="short_name">
              <ElInput
                v-model="payloadRef.short_name"
                :disabled="!isOwner"
                placeholder="Short business name"
              >
                <ElTooltip
                  slot="suffix"
                  class="item"
                  effect="light"
                  placement="right"
                >
                  <div slot="content">
                    We'll use this in text messages to prospects. A shortened name will keep the messages clear and concise.
                  </div>
                  <i class="sdicon-info-circle font-21 text-gray-dark" />
                </ElTooltip>
              </ElInput>
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div class="col-12 col-md-6">
          <SdFloatLabel with-optional>
            <ElFormItem
              prop="billing_email"
              :rules="{ validator: validateBillingEmail }"
            >
              <ElInput
                v-model="payloadRef.new_billing_email"
                :disabled="!isOwner"
                placeholder="Billing email"
              >
                <ElTooltip
                  v-if="$viewport.gt.sm && (!payloadRef.new_billing_email || payloadRef.new_billing_email === authUser.first_business_user_email)"
                  slot="suffix"
                  class="item"
                  effect="light"
                  placement="right"
                >
                  <div slot="content">
                    We'll be sending invoices to
                    <strong>
                      {{ authUser.first_business_user_email }} </strong>. Optionally, you can provide a different address
                    here
                  </div>
                  <i class="sdicon-info-circle font-21 text-gray-dark" />
                </ElTooltip>
              </ElInput>
            </ElFormItem>
          </SdFloatLabel>
          <ElFormItem prop="billing_email">
            <ElInput
              v-model="payloadRef.billing_email"
              hidden
            />
          </ElFormItem>
        </div>
        <div class="col-12 col-md-6">
          <SdFloatLabel with-optional>
            <ElFormItem
              prop="inquiry_notification_email"
              :rules="{ type: 'email', message: 'Must be a valid email address', trigger: 'blur' }"
            >
              <ElTooltip
                :visible-arrow="false"
                :disabled="$viewport.lt.md"
                popper-class="popover-panel"
                placement="right-start"
              >
                <template slot="content">
                  <i class="sdicon-info-circle" />
                  <p>
                    The email address where you want to receive inquiries for your properties.
                  </p>
                </template>
                <SdFloatLabel>
                  <ElInput
                    v-model="payloadRef.inquiry_notification_email"
                    placeholder="Inquiry notification email"
                  />
                </SdFloatLabel>
              </ElTooltip>
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div class="col-12 col-md-6">
          <ElFormItem prop="address.line1">
            <SdFloatLabel label="Address line 1">
              <ElInput
                v-model="payloadRef.address.line1"
                placeholder="Address line 1"
              />
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12 col-md-6">
          <ElFormItem prop="address.line2">
            <SdFloatLabel label="Address line 2">
              <ElInput
                v-model="payloadRef.address.line2"
                placeholder="Address line 2"
              />
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12 col-md-6">
          <ElFormItem prop="address.city">
            <SdFloatLabel label="City">
              <ElInput
                v-model="payloadRef.address.city"
                placeholder="City"
              />
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12 col-md-6">
          <ElFormItem prop="address.state">
            <SdFloatLabel label="State">
              <ElSelect
                v-model="payloadRef.address.state"
                placeholder="State"
                :disabled="payloadRef.address.country !== 'US'"
              >
                <ElOption
                  v-for="{ label, value } in StateOptions"
                  :key="value"
                  :label="label"
                  :value="value"
                />
              </ElSelect>
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12 col-md-6">
          <ElFormItem prop="address.country">
            <SdFloatLabel label="Country">
              <ElSelect
                v-model="payloadRef.address.country"
                placeholder="Country"
                @input="countryChanged"
              >
                <ElOption
                  v-for="{ label, value } in CountryOptions"
                  :key="value"
                  :label="label"
                  :value="value"
                />
              </ElSelect>
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12 col-md-6">
          <ElFormItem prop="address.postal_code">
            <SdFloatLabel label="Postal code">
              <ElInput
                v-model="payloadRef.address.postal_code"
                placeholder="Postal code"
              />
            </SdFloatLabel>
          </ElFormItem>
        </div>
        <div class="col-12 col-md-12">
          <SdImageField
            :disabled="!isOwner"
            placeholder-text="Business Logo"
            :height="256"
            :src="payloadRef.logo_url"
            :quality="1"
            :prevent-white-space="false"
            @set-image-field="setImageField"
            @unset-image-field="unsetImageField"
          />
        </div>
        <div class="col-12">
          <SdFloatLabel with-optional>
            <ElFormItem
              prop="website_url"
              :rules="{ validator: validateWebsiteUrl }"
            >
              <ElTooltip
                :visible-arrow="false"
                :disabled="$viewport.lt.md"
                popper-class="popover-panel"
                placement="right-start"
              >
                <template slot="content">
                  <i class="sdicon-info-circle" />
                  <p>
                    The URL to your main property management site. It will be used when linking prospects to your site.
                  </p>
                </template>
                <SdFloatLabel>
                  <ElInput
                    v-model="payloadRef.website_url"
                    placeholder="Website URL"
                  />
                </SdFloatLabel>
              </ElTooltip>
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div class="col-12">
          <SdFloatLabel with-optional>
            <ElFormItem
              prop="screening_criteria_url"
              :rules="{ validator: validateScreeningCriteriaUrl }"
            >
              <ElTooltip
                :visible-arrow="false"
                :disabled="$viewport.lt.md"
                popper-class="popover-panel"
                placement="right-start"
              >
                <template slot="content">
                  <i class="sdicon-info-circle" />
                  <p>
                    This will be shown to users who do not pass pre-screening.
                  </p>
                </template>
                <SdFloatLabel>
                  <ElInput
                    v-model="payloadRef.screening_criteria_url"
                    placeholder="Screening Criteria Url"
                  />
                </SdFloatLabel>
              </ElTooltip>
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div class="col-12">
          <SdFloatLabel with-optional>
            <ElFormItem
              prop="faq_url"
              :rules="{ validator: validateFaqUrl }"
            >
              <ElTooltip
                :visible-arrow="false"
                :disabled="$viewport.lt.md"
                popper-class="popover-panel"
                placement="right-start"
              >
                <template slot="content">
                  <i class="sdicon-info-circle" />
                  <p>
                    The URL to the FAQ page of your site. It will be shown to prospects on the Showdigs listing pages.
                  </p>
                </template>
                <SdFloatLabel>
                  <ElInput
                    v-model="payloadRef.faq_url"
                    placeholder="FAQ URL"
                  />
                </SdFloatLabel>
              </ElTooltip>
            </ElFormItem>
          </SdFloatLabel>
        </div>
        <div class="col-12">
          <h3 class="font-21 mt-4">
            Prospect Communication
          </h3>
          <p class="font-15">
            You can choose what contact info we give prospects who inquire for your properties.
          </p>
          {{
            payloadRef.should_show_contact
              ? `Visitors on the property's scheduling page will see the business name.
          Prospects will be provided this email and/or this phone number.`
              : `Visitors on the property's scheduling page will see the listing owner's full name.
          Prospects will be provided the listing owner's public phone number.`
          }}
        </div>
        <div
          class="col-12"
          :class="$viewport.gt.md ? '' : 'd-flex flex-column'"
        >
          <el-radio
            v-model="payloadRef.should_show_contact"
            :disabled="!isOwner"
            :label="false"
            class="mb-0"
          >
            <strong class="text-gray-darker">
              Listing owner contact details
            </strong>
          </el-radio>
          <el-radio
            v-model="payloadRef.should_show_contact"
            :disabled="!isOwner"
            :label="true"
            class="mb-0"
          >
            <strong class="text-gray-darker">
              Business contact details
            </strong>
          </el-radio>
        </div>
        <div
          v-if="payloadRef.should_show_contact"
          class="col-12"
        >
          <div class="row align-items-center">
            <div class="col-12 pt-2">
              Please enter at least one of the following points of contact for prospects.
            </div>
            <div class="col-12 col-md-6">
              <SdFloatLabel>
                <ElFormItem
                  prop="contact_email"
                  :rules="{ validator: validateContactDetails }"
                >
                  <ElInput
                    v-model="payloadRef.contact_email"
                    :disabled="!isOwner"
                    placeholder="Business contact email"
                  />
                </ElFormItem>
              </SdFloatLabel>
            </div>
            <div class="col-12 col-md-6">
              <SdFloatLabel>
                <ElFormItem
                  prop="contact_phone"
                  :rules="{ validator: validateContactDetails }"
                >
                  <ElInput
                    v-model="payloadRef.contact_phone"
                    class="input-with-append"
                    :disabled="!isOwner"
                    placeholder="Business phone"
                  >
                    <SdFloatLabel
                      slot="append"
                      for-input="contact_phone_extension"
                    >
                      <ElFormItem prop="contact_phone_extension">
                        <ElInput
                          id="contact_phone_extension"
                          v-model="payloadRef.contact_phone_extension"
                          class="input-phone-extension"
                          type="text"
                          placeholder="Ext"
                        />
                      </ElFormItem>
                    </SdFloatLabel>
                  </ElInput>
                </ElFormItem>
              </SdFloatLabel>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end mt-3 mb-3">
          <ElButton
            v-if="authUser.role === Role.ACCOUNT_OWNER"
            type="primary"
            size="large"
            :loading="uiFlags.businessDetailsFormLoading"
            @click="updateBusinessDetails"
          >
            Update
          </ElButton>
        </div>
      </ElForm>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import BusinessListingsSnippets from '@/components/settings/BusinessListingsSnippets';
import TeamMembersTable from '@/components/profile/TeamMembersTable';
import SdImageField from '@/components/common/form/SdImageField';
import SdFloatLabel from '@/components/common/form/SdFloatLabel';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import Role from '../../constants/Role';
import regex from '../../constants/Regex';
import StateOptions from '../../constants/StateOptions';
import CountryOptions from '../../constants/CountryOptions';

const regexConstants = regex;
const businessDetailsValidFieldsToUpdate = [
  'name',
  'short_name',
  'new_billing_email',
  'billing_email',
  'address',
  'logo',
  'logo_url',
  'website_url',
  'faq_url',
  'should_show_contact',
  'contact_email',
  'contact_phone',
  'contact_phone_extension',
  'screening_criteria_url',
  'inquiry_notification_email',
];

export default {
  name: 'BusinessSettingsBusinessTab',
  components: {
    SdFloatLabel,
    BusinessListingsSnippets,
    TeamMembersTable,
    SdImageField,
  },
  props: {
    authUser: {
      type: Object,
      default: () => { },
    },
    payload: {
      default: () => { },
      type: Object,
    },
    business: {
      type: Object,
      default: () => { },
    },
    isOwner: {
      type: Boolean,
      default: () => { },
    },
    rules: {
      type: Object,
      default: () => { },
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const payloadRef = ref(cloneDeep(props.payload));
    const originalStateRef = ref({
      payload: null,
    });
    const uiFlags = reactive({
      businessDetailsFormLoading: false,
      hasUnsavedChanges: false,
    });

    watch(
      () => props.payload,
      () => {
        payloadRef.value = cloneDeep(props.payload);
      },
    );

    store.subscribe((mutation) => {
      // We need this because we must update the logo variables from the server response
      if (mutation.type.includes('BusinessSettings/')) {
        const cloneDeepData = cloneDeep(props.payload);
        payloadRef.value.logo = cloneDeepData?.logo;
        payloadRef.value.logo_url = cloneDeepData?.logo_url;
      }
    });

    return {
      originalStateRef,
      uiFlags,
      payloadRef,
      Role,
      StateOptions,
      CountryOptions,
      updateBusinessDetails,
      updatePayloadListingApplyButton,
      setImageField,
      unsetImageField,
      validateWebsiteUrl,
      validateFaqUrl,
      validateBillingEmail,
      countryChanged,
      validateContactDetails,
      validateBusinessName,
      hasChanges,
      validateScreeningCriteriaUrl,
    };

    function hasChanges(originalPayload) {
      const payloadClone = cloneDeep({ ...payloadRef.value });
      return checkChange(formsFields.businessDetails, originalPayload, payloadClone);
    }

    function checkChange(formFields, originalPayload, payloadClone) {
      for (const name of formFields) {
        const originalValue = get(originalPayload, name);
        const currentValue = get(payloadClone, name);
        if (!isEqual(originalValue, currentValue) && !(originalValue === null && currentValue === '')) {
          if (name === 'logo' && (typeof originalValue === 'string' || originalValue === null) && currentValue instanceof Blob) {
            return true;
          }
          return true;
        }
      }
      return false;
    }

    async function updateBusinessDetails() {
      const fields = {};
      for (const key of businessDetailsValidFieldsToUpdate) {
        fields[key] = payloadRef.value[key];
      }
      uiFlags.businessDetailsFormLoading = true;

      try {
        if (!fields.new_billing_email) {
          fields.billing_email = props.authUser.first_business_user_email;
        } else if (fields.new_billing_email) {
          fields.billing_email = fields.new_billing_email;
        }
        if (payloadRef.value.logo && payloadRef.value.logo.size) {
          const formData = new FormData();
          formData.append('file', payloadRef.value.logo);
          const response = await store.dispatch('Auth/uploadPicture', formData);
          fields.logo = response.name;
        }
        await context.refs.form.validate();
        await commitPayload('business_details', fields);
        showSuccessNotify(context, 'Business details were updated');
        uiFlags.businessDetailsFormLoading = false;
      } catch (error) {
        uiFlags.businessDetailsFormLoading = false;
        if (!(error instanceof Error)) {
          showErrorNotify(context, 'A section in screen is incomplete, please check all required fields');
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      }
    }

    async function updatePayloadListingApplyButton(payload) {
      await store.dispatch('BusinessSettings/updateAndCommitPayload', payload);
    }

    async function commitPayload(meta, payload) {
      await store.dispatch('BusinessSettings/updateAndCommitPayload', {
        meta,
        payload,
      });
    }

    async function setImageField(imgObj) {
      payloadRef.value.logo = imgObj;
    }

    async function unsetImageField() {
      payloadRef.value.logo = null;
    }

    function validateWebsiteUrl(rule, value, callback) {
      if (!value) {
        return callback();
      }
      if (!value.match(regexConstants.url)) {
        return callback(new Error('Website URL is not valid'));
      }
      if (!value.match(regexConstants.http_protocol)) {
        payloadRef.value.website_url = `https://${value}`;
      }
      return callback();
    }

    function validateFaqUrl(rule, value, callback) {
      if (!value) {
        return callback();
      }
      if (!value.match(regexConstants.url)) {
        return callback(new Error('FAQ URL is not valid'));
      }
      if (!value.match(regexConstants.http_protocol)) {
        payloadRef.value.faq_url = `https://${value}`;
      }
      return callback();
    }

    function validateBillingEmail(rule, value, callback) {
      if (!billingMailIsSameAsAccountOwnerMail) {
        callback(new Error('Email is required'));
      } else if (!value.match(regex.email) && payloadRef.value.new_billing_email) {
        callback('Invalid email');
      } else {
        callback();
      }
    }

    function validateBusinessName(rule, value, callback) {
      if (!value) {
        callback(new Error('Business is required'));
      } else {
        callback();
      }
    }
    function countryChanged() {
      if (payloadRef.value.address.country !== 'US') {
        payloadRef.value.address.state = '';
      }
    }

    function billingMailIsSameAsAccountOwnerMail() {
      return props.business.value.billing_email === props.authUser.first_business_user_email;
    }

    function validateContactDetails(rule, value, callBack) {
      const phoneOrEmailFilled = !!payloadRef.value.contact_email || !!payloadRef.value.contact_phone;
      if (payloadRef.value.should_show_contact === true && !phoneOrEmailFilled) {
        callBack('Please fill in either business contact email or business phone');
      } else {
        callBack();
      }
    }

    function validateScreeningCriteriaUrl(rule, value, callback) {
      if (!value) {
        return callback();
      }
      if (!value.match(regexConstants.url)) {
        return callback(new Error('Screening criteria URL is not valid'));
      }
      if (!value.match(regexConstants.http_protocol)) {
        payloadRef.value.screening_criteria_url = `https://${value}`;
      }
      return callback();
    }
  },
};

const formsFields = {
  businessDetails: [
    'name',
    'short_name',
    'billing_email',
    'address.line1',
    'address.line2',
    'address.city',
    'address.state',
    'address.country',
    'address.postal_code',
    'logo_url',
    'logo',
    'website_url',
    'faq_url',
    'should_show_contact',
    'contact_email',
    'contact_phone',
    'screening_criteria_url',
  ],
};
</script>

<style lang="scss" scoped>
.mobile {
  .text-medium {
    font-size: $--font-size-medium;
  }
}
</style>
