<template>
  <div
    class="row no-gutters justify-content-between align-items-center template-option pb-2"
  >
    <TemplateDeleteDialog
      :template="option"
      :visible="uiFlags.openDeleteTemplateDialog"
      @close="closeDialog"
      @reorganize-template-list="reorganizeTemplateList"
    />
    <div class="draggable-element d-flex">
      <i class="sdicon-draggable pointer font-15" />
      <div class="font-17 ml-2">
        {{ option.title }}
        <div
          v-if="option.description"
          class="justify-content-between font-14 text-gray"
        >
          {{ option.description }}
        </div>
        <div
          v-else
          class="row no-gutters justify-content-between font-14 text-gray d-flex"
        >
          API ID:
          <input
            :id="`option-key-${option.uuid}-input`"
            class="font-14 border-0 elipsis input-option text-gray ml-1"
            type="text"
            spellcheck="false"
            :value="option.uuid"
          >
          <div
            class="text-right pointer"
            @click="copyLink(`option-key-${option.uuid}-input`)"
          >
            <span class="font-13">
              <i
                :class="isLandingPageLinkCopied[`option-key-${option.uuid}-input`]
                  ? 'sdicon-check pointer font-14 text-success mr-n1'
                  : 'sdicon-copy pointer font-14 text-gray-dark'"
              />
              {{ isLandingPageLinkCopied[`option-key-${option.uuid}-input`] ? "Copied" : "Copy" }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="get(option, 'action') === 'switch'"
      class="col-1"
    >
      <SdSwitchSaved
        :is-saved="uiFlags.isSaved"
        @hide-text="hideSwitchText('isSaved')"
      >
        <ElSwitch
          v-model="option.enabled"
          :width="44"
          @change="updateEnabled"
        />
      </SdSwitchSaved>
    </div>
    <div
      v-else
      class="edit-actions"
    >
      <i
        class="sdicon-pencil-edit font-17"
        :class="uiFlags.editDisabled ? 'disabled' : 'pointer'"
        @click="editTemplate"
      />
      <i
        class="ml-2 sdicon-trash font-21 "
        :class="uiFlags.editDisabled ? 'disabled' : 'text-danger pointer'"
        @click="openDeleteTemplateDialog"
      />
    </div>
  </div>
</template>

<script>

import SdSwitchSaved from '@/components/common/form/SdSwitchSaved.vue';
import {
  reactive,
  ref,
  set,
  watch,
} from '@vue/composition-api';
import TemplateDeleteDialog from '@/components/settings/BusinessSettingsShowings/TemplateDeleteDialog.vue';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import get from 'lodash.get';

export default {
  name: 'TemplateOption',
  components: {
    TemplateDeleteDialog,
    SdSwitchSaved,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    formIsOpened: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const { $store } = context.root;
    const uiFlags = reactive({
      editDisabled: false,
      openDeleteTemplateDialog: false,
      isSaved: false,
    });
    const isLandingPageLinkCopied = reactive({});
    watch(
      () => props.formIsOpened,
      () => {
        uiFlags.editDisabled = props.formIsOpened;
      },
    );

    return {
      editTemplate,
      uiFlags,
      openDeleteTemplateDialog,
      closeDialog,
      reorganizeTemplateList,
      updateEnabled,
      get,
      hideSwitchText,
      copyLink,
      isLandingPageLinkCopied,
    };

    function editTemplate() {
      if (!uiFlags.editDisabled) {
        uiFlags.editDisabled = true;
        context.emit('edit-template', props.option.id);
      }
    }
    async function updateEnabled() {
      try {
        const result = await $store.dispatch('ScreeningTemplates/update', props.option);
        uiFlags.isSaved = true;
      } catch (error) {
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        props.option.enabled = !props.option.enabled;
        showErrorNotify(context, error.message);
      }
    }
    function openDeleteTemplateDialog() {
      if (!uiFlags.editDisabled) {
        uiFlags.openDeleteTemplateDialog = true;
      }
    }

    function copyLink(id) {
      const copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand('copy');
      set(isLandingPageLinkCopied, id, true);
    }
    function closeDialog() {
      uiFlags.openDeleteTemplateDialog = false;
    }
    function reorganizeTemplateList() {
      context.emit('reorganize-template-list', props.option.id);
    }

    function hideSwitchText(key) {
      uiFlags[key] = false;
    }
  },
};
</script>

<style lang="scss">
.draggable-element {
  width: 80%;
}
.sdicon-draggable {
  color: #B4C5D0;
}
.template-option .el-divider--horizontal {
  height: 2px !important;
}
.edit-actions .disabled {
  color: #B4C5D0;
;
}
.el-popover__reference-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.option-key {
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.input-option {
  outline: none;
  &:focus-visible {
    outline: none;
    background: none;
  }
  &:focus {
    outline: none;
    background: none;
  }
}
</style>
