<template>
  <div class="row no-gutters justify-content-between listings-widgets">
    <h3 class="font-21">
      Listings Widgets
    </h3>
    <div class="col-12">
      <p class="font-15">
        Use these code snippets to display all your current vacancies on your website. <br>
        Check out our <a
          href="https://help.showdigs.com/kb/guide/en/using-the-listing-widget-QDcqMRwvKo/Steps/1788554"
          target="_blank"
        >configuration guide here</a> for more details.
      </p>
    </div>
    <div class="col-12 listings-widgets-input">
      <div>
        Code snippet to place inside your website's &lt;head&gt; tag
      </div>
      <ElInput
        :value="`${widgetScriptSnippet}</script>`"
        disabled
      >
        <template slot="append">
          <SdCopyToClipboard
            :copy="`${widgetScriptSnippet}</script>`"
            input-id="copy-widget-script"
            text="Copy"
            text-css-class="copy-code"
          />
        </template>
      </ElInput>
    </div>
    <div class="col-12 mt-3 listings-widgets-input">
      <div>
        Element snippet to place inside the listing page content where you want the listings list to be displayed
      </div>
      <ElInput
        :value="elementSnippet"
        disabled
      >
        <template slot="append">
          <SdCopyToClipboard
            :copy="elementSnippet"
            input-id="copy-widget-element"
            text="Copy"
            text-css-class="copy-code"
          />
        </template>
      </ElInput>
    </div>
    <div class="col-12 mt-3 listings-widgets-input">
      <div>
        Listings standalone website link
      </div>
      <ElInput
        :value="listingsUrl"
        disabled
      >
        <template slot="append">
          <SdCopyToClipboard
            :copy="listingsUrl"
            input-id="copy-widget-listings"
            text="Copy"
            text-css-class="copy-code"
          />
        </template>
      </ElInput>
    </div>
    <div class="col-12 d-flex justify-content-between my-3">
      <span class="font-weight-normal pr-3 font-16">
        Show the 'apply now' link for available listings
      </span>
      <SdSwitchSaved
        :is-saved="uiFlags.listingWidgetShowApplyNow"
        @hide-text="hideSwitchText('listingWidgetShowApplyNow')"
      >
        <ElSwitch
          v-model="showApplyButtonRef"
          :disabled="authUser.role !== Role.ACCOUNT_OWNER"
          :width="44"
          @change="toggleApplyButton"
        />
      </SdSwitchSaved>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';
import SdSwitchSaved from '@/components/common/form/SdSwitchSaved';
import Role from '@/constants/Role';
import SdCopyToClipboard from '@/components/common/SdCopyToClipboard';

export default {
  name: 'BusinessListingsSnippets',
  components: {
    SdSwitchSaved,
    SdCopyToClipboard,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const uiFlags = reactive({
      listingWidgetShowApplyNow: false,
    });
    const store = context.root.$store;
    const authUser = store.state.Auth.user;
    const showApplyButtonRef = ref(props.business.show_listing_apply_button);
    const widgetUrl = process.env.VUE_APP_WIDGETS_URL;
    const listingsUrl = `${process.env.VUE_APP_LISTINGS_URL}${props.business.id}`;
    const widgetScriptSnippet = computed(computeWidgetScriptSnippet);
    const elementSnippet = '<div id="showdigs-listings"></div>';

    return {
      uiFlags,
      showApplyButtonRef,
      widgetScriptSnippet,
      elementSnippet,
      listingsUrl,
      Role,
      authUser,
      toggleApplyButton,
      hideSwitchText,
    };

    function computeWidgetScriptSnippet() {
      if (!widgetUrl || !props.business.id) {
        return '';
      }

      return `<script id="showdigs-widget-script" src="${widgetUrl}widget.js?id=${props.business.id}">`;
    }

    function hideSwitchText(key) {
      uiFlags[key] = false;
    }

    async function toggleApplyButton() {
      context.emit('toggle-listing-apply-button', {
        meta: 'show_listing_apply_button',
        payload: {
          show_listing_apply_button: showApplyButtonRef.value,
        },
      });
      uiFlags.listingWidgetShowApplyNow = true;
    }
  },
};
</script>

<style lang="scss">
.listings-widgets {
  .el-input-group__append {
    padding-left: 3px;
    padding-right: 3px;
  }
}
.copy-code {
  color: theme-color(primary);
}

.listings-widgets-input {
  .el-input.is-disabled .el-input__inner{
    border: none;
  }
  .el-input-group{
    border: 1px solid #7A94A5;
    border-radius: 5px;
  }
}
</style>
