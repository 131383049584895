<template>
  <ElDialog
    id="delete-template-dialog"
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="visible"
    :width="$viewport.lt.md ? '100vw': '500px'"
    @close="preClose"
  >
    <div slot="title">
      <h2 class="mb-1">
        Delete pre-screening template
      </h2>
      <h3 class="text-gray-dark font-weight-normal">
        Are you sure you want to delete {{ template.title }}?
      </h3>
      <div
        v-if="unitLinks"
        class="text-gray-dark font-weight-normal"
      >
        This template is currently in use. Please update the pre-screening criteria for the following properties before deleting the template.
        <div
          v-for="unit in unitLinks"
          :key="unit.id"
        >
          <router-link
            :to="{ name: 'app.units.show', params: { id: unit.property_id, unit_id: unit.id } }"
            class="font-17 font-weight-bolder"
          >
            {{ unit.property.short_address }}
            <span
              v-if="unit.name"
              class="font-weight-normal"
            > - {{ unit.name }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button
        size="medium"
        @click="$emit('close')"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        size="medium"
        :loading="uiFlags.loading"
        :disabled="!!unitLinks"
        @click="deleteTemplate"
      >
        Yes, delete
      </el-button>
    </div>
  </ElDialog>
</template>

<script>

import { reactive, ref } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import store from '@/store';

export default {
  name: 'TemplateDeleteDialog',
  props: {
    template: {
      required: true,
      type: Object,
    },
    visible: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    const uiFlags = reactive({
      loading: false,
    });
    const unitLinks = ref(null);
    return {
      uiFlags,
      deleteTemplate,
      unitLinks,
      preClose,
    };

    async function deleteTemplate() {
      try {
        const result = await store.dispatch('ScreeningTemplates/destroy', props.template.id);
        if (typeof result === 'number') {
          showSuccessNotify(context, 'Screening template deleted successfully');
          context.emit('reorganize-template-list', props.template.id);
        } else {
          unitLinks.value = result;
        }
      } catch (error) {
        showErrorNotify(context, error.message);
      }
    }

    function preClose() {
      unitLinks.value = null;
      context.emit('close');
    }
  },
};
</script>

<style lang="scss">
</style>
