import Plan from '@/constants/Plan';

export {
  isStandardTypePlan,
  isPhoneLineSupported,
  getPlanPeriod,
};

function isStandardTypePlan(plan) {
  return [Plan.STANDARD, Plan.MONTHLY_STANDARD, Plan.ANNUAL_STANDARD, Plan.QUARTERLY_STANDARD].includes(plan.name);
}

function isPhoneLineSupported(plan) {
  return [Plan.STANDARD, Plan.MONTHLY_STANDARD, Plan.ANNUAL_STANDARD, Plan.QUARTERLY_STANDARD, Plan.PRO, Plan.PREMIUM].includes(plan.name);
}

function getPlanPeriod(plan) {
  switch (plan.name) {
    case Plan.ANNUAL_STANDARD:
      return 'Annually';
    case Plan.QUARTERLY_STANDARD:
      return 'Quarterly';
    default:
      return 'Monthly';
  }
}
