<template>
  <ElDialog
    visible
    :width="$viewport.lt.md ? 'auto' : '700px'"
    :fullscreen="$viewport.lt.md"
    :destroy-on-close="true"
    @close="$emit('close', true)"
  >
    <div
      slot="title"
      class="px-4"
    >
      <h2 class="text-black font-21 mb-0 text-center">
        Disconnect your PMS
      </h2>
      <div
        class="col-12 pt-1 text-center text-secondary"
      >
        Please confirm that you would like to disconnect Showdigs from your PMS.
        Property and unit information that’s in Showdigs will remain,
        but no additional information will be added or synchronized.
      </div>
    </div>
    <div
      slot="footer"
      class="row justify-content-center px-4"
    >
      <div
        class="col-md-4 col-12"
      >
        <ElButton
          class="w-100"
          size="medium"
          @click="$emit('close', true)"
        >
          Cancel
        </ElButton>
      </div>
      <div
        class="col-6 col-md-4"
      >
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          :disabled="uiFlags.isLoading"
          :loading="uiFlags.isLoading"
          @click="disconnect"
        >
          Disconnect
        </ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<script>

import { reactive } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';

export default {
  name: 'PMSIntegrationSettingDisconnectDialog',
  props: {
    businessSource: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const uiFlags = reactive({
      isLoading: false,
    });
    return {
      uiFlags,
      disconnect,
    };

    async function disconnect() {
      uiFlags.isLoading = true;
      try {
        await store.dispatch('BusinessSource/destroy', {
          businessSourceId: props.businessSource.id,
        });
        showSuccessNotify(context, `${props.businessSource.source_type} source has been deleted`);
      } catch (e) {
        showErrorNotify(context, 'Something went wrong. Please try again');
      }
      context.emit('close', false);
    }
  },
};
</script>
