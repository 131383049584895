<template>
  <ElDialog
    id="business-token-revoke-dialog"
    top="30vh"
    :center="true"
    :show-close="false"
    :visible.sync="uiFlags.isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
    :destroy-on-close="true"
  >
    <div slot="title">
      <div
        class="font-weight-strong font-21 text-center"
      >
        Are you sure you want to revoke access?
      </div>
      <div class="font-15 text-center mt-1">
        This action can’t be undone
      </div>
    </div>
    <span
      class="row justify-content-center"
    >
      <div
        class="col-6 col-md-4"
      >
        <ElButton
          class="w-100"
          size="medium"
          @click="uiFlags.isVisible = false"
        >
          Cancel
        </ElButton>
      </div>
      <div
        class="col-6 col-md-4"
      >
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          :loading="uiFlags.loading"
          @click="revoke"
        >
          Revoke
        </ElButton>
      </div>
    </span>
  </ElDialog>
</template>

<script>
import { reactive } from '@vue/composition-api';
import CompletedMarker from '@/assets/icons/list-mark.svg';
import { showSuccessNotify } from '@/utils/NotifyUtil';

export default {
  name: 'BusinessTokenRevokeDialog',
  setup(props, context) {
    const uiFlags = reactive({
      isVisible: false,
      loading: false,
      apiError: null,
    });

    return {
      uiFlags,
      open,
      CompletedMarker,
      revoke,
    };

    async function revoke() {
      uiFlags.loading = true;
      await context.root.$store.dispatch('Business/revokeToken');
      showSuccessNotify(context, 'The API key access has been revoked.');
      context.emit('revoke-token');
      uiFlags.isVisible = false;
    }

    function open() {
      uiFlags.isVisible = true;
      uiFlags.loading = false;
    }
  },
};
</script>

<style lang="scss">
#business-token-revoke-dialog {
  @include media-breakpoint-down(md) {
    height: 25vh;
    margin-top: 10vh;
    .el-dialog {
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      align-self: center;
      border-top-left-radius: $app-border-radius-extra-large;
      border-top-right-radius: $app-border-radius-extra-large;
    > .el-dialog__body {
      flex: 1;
      }
    }
  }
}
</style>
