<template>
  <div>
    <ElFormItem
      :rules="{ required: true, message: 'Schedule template name is required' }"
      prop="name"
      class="mt-3 mb-4"
    >
      <SdFloatLabel>
        <ElInput
          v-model="scheduleTemplatePayload.name"
          autofocus
          placeholder="Schedule template name"
        />
      </SdFloatLabel>
    </ElFormItem>
    <div
      v-for="(showingWindow, index) in sortedShowingWindows"
      :key="getShowingWindowKey(index, showingWindow)"
      class="d-flex align-items-center mb-4"
    >
      <ShowingWindow
        :validation-prop="`showing_windows.${index}`"
        :showing-window="showingWindow"
        :showing-windows="sortedShowingWindows"
        :weekdays-options="weekdaysOptions"
        :hours-options="hoursOptions"
        :assignees-options="assigneeOptions"
        @update="(e) => handleShowingWindowUpdate(index, e)"
        @remove="removeShowingWindow(index)"
      />

      <div
        class="delete pointer"
        @click.stop="removeShowingWindow(index)"
      >
        <ElTooltip
          content="Delete"
          placement="right"
        >
          <i class="sdicon-trash font-21" />
        </ElTooltip>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <ElButton
        size="small"
        @click="addShowingWindow"
      >
        + Add showing window
      </ElButton>
    </div>
  </div>
</template>

<script>
import {
  computed, reactive, ref, watch,
} from '@vue/composition-api';
import ShowingWindow from '@/components/settings/ScheduleTemplates/ShowingWindow.vue';
import Vue from 'vue';
import cloneDeep from 'lodash.clonedeep';
import { getSortedShowingWindows } from '@/utils/ShowingWindowUtil';

const defaultShowingWindow = {
  week_day: null,
  from_hour: null,
  to_hour: null,
  assignee_id: null,
};

export default {
  name: 'ScheduleTemplate',
  components: { ShowingWindow },
  props: {
    scheduleTemplate: {
      required: true,
      type: Object,
    },
    weekdaysOptions: {
      required: true,
      type: Array,
    },
    hoursOptions: {
      required: true,
      type: Array,
    },
    assigneeOptions: {
      required: true,
      type: Array,
    },
  },
  setup(props, context) {
    // Schedule payloads
    const scheduleTemplatePayload = ref(cloneDeep(props.scheduleTemplate));
    // Immutable ShowingWindows to pass as prop
    const sortedShowingWindows = computed(() => getSortedShowingWindows(scheduleTemplatePayload.value.showing_windows));
    // UI flags
    const uiFlags = reactive({ isProcessing: false });

    watch(scheduleTemplatePayload.value, () => {
      context.emit('update', scheduleTemplatePayload.value);
    }, { deep: true });

    const getShowingWindowKey = (index, showingWindow) => `${index}-${showingWindow.week_day}-${showingWindow.from_hour}-${showingWindow.to_hour}`;

    const handleShowingWindowUpdate = (index, showingWindow) => {
      Vue.set(scheduleTemplatePayload.value.showing_windows, index, showingWindow);
    };

    const addShowingWindow = () => {
      const updatedScheduleTemplate = cloneDeep(scheduleTemplatePayload.value);
      updatedScheduleTemplate.showing_windows = [...scheduleTemplatePayload.value.showing_windows, { ...defaultShowingWindow }];
      context.emit('update', updatedScheduleTemplate);
      scheduleTemplatePayload.value = updatedScheduleTemplate;
    };

    const removeShowingWindow = (index) => {
      scheduleTemplatePayload.value.showing_windows.splice(index, 1);
    };

    return {
      scheduleTemplatePayload,
      sortedShowingWindows,
      uiFlags,
      getShowingWindowKey,
      handleShowingWindowUpdate,
      addShowingWindow,
      removeShowingWindow,
    };
  },
};
</script>
