<template>
  <div class="row">
    <div class="col-12 col-md-10">
      <div class="mb-3">
        <h3 class="font-21">
          ILS Syndication Service
        </h3>
        <p v-if="isBusinessSourceAppfolio">
          <strong>AppFolio:</strong> Since you have AppFolio integration enabled, we recommend syndication your
          listings through AppFolio. All syndication-related changes should be made there.
          Showdigs will not syndicate any listings.
        </p>
        <p v-else>
          Syndication allows you to spend less time posting ads and more time connecting with leads.
          If you are not syndicating through another application, please enable one or all of the available networks, below.
        </p>
      </div>
      <ZillowSettingsBlock
        :business="businessRef"
        :disabled="isDisabled"
        class="mt-4"
      />
      <ZumperSettingsBlock
        :business="businessRef"
        :disabled="isDisabled"
        class="mt-4"
      />
      <ApartmentsDotComSettingsBlock
        :business="businessRef"
        :disabled="isDisabled"
        class="mt-4"
      />
    </div>
  </div>
</template>

<script>
import ZillowSettingsBlock from '@/components/settings/Syndication/ZillowSettingsBlock.vue';
import ZumperSettingsBlock from '@/components/settings/Syndication/ZumperSettingsBlock.vue';
import ApartmentsDotComSettingsBlock from '@/components/settings/Syndication/ApartmentsDotComSettingsBlock.vue';
import { BusinessSourceTypes } from '@/constants/BusinessSource';
import { reactive } from '@vue/composition-api';
import get from 'lodash.get';

export default {
  name: 'BusinessSettingsSyndicationTab',
  components: {
    ApartmentsDotComSettingsBlock,
    ZumperSettingsBlock,
    ZillowSettingsBlock,
  },
  props: {
    business: {
      type: Object,
      default: () => {
      },
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const businessRef = reactive(props.business);
    const businessSourceType = get(props.business, 'source.source_type');
    const isBusinessSourceAppfolio = businessSourceType === BusinessSourceTypes.APPFOLIO;

    const isDisabled = !props.isOwner || isBusinessSourceAppfolio;

    const uiFlags = reactive({
      syndicationFormLoading: false,
    });

    return {
      businessRef,
      isDisabled,
      isBusinessSourceAppfolio,
      uiFlags,
    };
  },
};
</script>
