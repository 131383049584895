<template>
  <div class="col-12">
    <div class="row no-gutters justify-content-between">
      <h3 class="font-weight-normal">
        Restrict Self-Showing Hours
      </h3>

      <div>
        <ElSwitch
          v-model="restrictSelfShowingHours"
          class="col-auto d-block"
          :disabled="disabled"
          :width="44"
          @change="toggleSetting"
        />
      </div>
    </div>
    <div class="row no-gutters mb-3">
      <span class="font-15">
        Restrict the starting or ending times of self-showings. <br>
      </span>
    </div>
    <div
      v-if="restrictSelfShowingHours"
      class="row"
    >
      <div class="col-6">
        <ElSelect
          v-model="localShowingHours.from"
          clearable
          placeholder="From"
          @change="updateShowingHours"
        >
          <ElOption
            v-for="time in timePickerOptions"
            :key="time"
            :label="time"
            :value="time"
          />
        </ElSelect>
      </div>
      <div class="col-6">
        <ElSelect
          v-model="localShowingHours.to"
          clearable
          placeholder="To"
          @change="updateShowingHours"
        >
          <ElOption
            v-for="time in timePickerOptions"
            :key="time"
            :label="time"
            :value="time"
          />
        </ElSelect>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import cloneDeep from 'lodash.clonedeep';

export default {
    props: {
        showingHours: {
            type: Object,
            default: null,
        },
        disabled: Boolean,
    },
    setup(props, { emit }) {
        const restrictSelfShowingHours = ref(props.showingHours !== null);
        const localShowingHours = ref(cloneDeep(props.showingHours));

        const updateShowingHours = () => {
            emit('change', localShowingHours.value);
        };

        const toggleSetting = (enabled) => {
            if (enabled) {
                localShowingHours.value = {
                    from: '10:00',
                    to: '18:00',
                };
            } else {
                localShowingHours.value = null;
            }

            updateShowingHours();
        };

        return {
            restrictSelfShowingHours,
            localShowingHours,
            toggleSetting,
            updateShowingHours,
            timePickerOptions: [
                '10:00',
                '10:30',
                '11:00',
                '11:30',
                '12:00',
                '12:30',
                '13:00',
                '13:30',
                '14:00',
                '14:30',
                '15:00',
                '15:30',
                '16:00',
                '16:30',
                '17:00',
                '17:30',
                '18:00',
            ],
        };
    },
};
</script>
