<template>
  <ElDialog
    id="import-appfolio-dialog"
    custom-class="center-dialog-vertically"
    :class="{'short-dialog':uiState.flow === uiFlow.ERROR}"
    :center="true"
    :show-close="uiState.flow === uiFlow.ERROR"
    visible
    width="500px"
    :fullscreen="$viewport.lt.md"
    :destroy-on-close="true"
    @close="close"
  >
    <div slot="title">
      <h2
        v-if="[uiFlow.INIT, uiFlow.IDENTIFIER].includes(uiState.flow)"
        class="text-black font-21 mb-0"
      >
        Connect your account
      </h2>
      <template v-if="[uiFlow.REVIEW, uiFlow.IMPORT].includes(uiState.flow)">
        <img
          src="@/assets/circle-check.svg"
          class="review-success-icon"
        >
        <h2 class="text-black font-21 mb-0 mt-4">
          {{ listingsRef.length }} Listings were found on AppFolio
        </h2>
        <h3
          v-if="existingListings.length"
          class="text-gray-dark font-17 font-weight-normal mt-1"
        >
          {{ existingListings.length }} already on Showdigs
        </h3>
      </template>
    </div>
    <div class="row d-flex justify-content-center mt-1 mb-3">
      <div
        v-if="[uiFlow.INIT, uiFlow.SCRAPE].includes(uiState.flow)"
        class="d-flex justify-content-center align-items-center loader-wrapper"
      >
        <SdSpinner />
      </div>
      <template v-if="uiState.flow === uiFlow.IDENTIFIER">
        <div class="col-12 pt-0 mb-3 text-center">
          Showdigs can automatically import your listings as they appear on your AppFolio generated website.
        </div>
        <div
          v-if="!isBasicPlan"
          class="col-12 pt-0 mb-3 text-center"
        >
          To start, enter your appfolio subdomain
        </div>
        <div class="col-12">
          <ElInput
            v-if="!isBasicPlan"
            v-model="identifier"
            class="source-identifier-input"
            placeholder="AppFolio Subdomain"
          >
            <template slot="prepend">
              https://
            </template>
            <template slot="append">
              .appfolio.com
            </template>
          </ElInput>
          <ElAlert
            v-if="isBasicPlan"
            class="mb-3"
            type="info"
            show-icon
            :closable="false"
          >
            <div>
              <div>This feature is only available in our Standard plan.</div>
              <div>
                <RouterLink
                  :to="{ name: 'app.billing', query: {modify:true}}"
                  target="_blank"
                >
                  Upgrade Plan
                </RouterLink>
              </div>
            </div>
          </ElAlert>
        </div>
      </template>
      <template v-if="[uiFlow.REVIEW, uiFlow.IMPORT].includes(uiState.flow)">
        <ElAlert
          type="info"
          show-icon
          :closable="false"
          class="p-2"
          tag-class="tagline-info"
          icon-class="sdicon-info-circle"
        >
          <span class="text-gray-darker font-15">Showdigs will keep importing new properties as they are added to your listings</span>
        </ElAlert>
        <div class="col-12 p-0 mt-4 position-relative">
          <div
            v-if="uiState.flow === uiFlow.IMPORT && listingsRef.length"
            class="import-loader"
          >
            <ElProgress
              class="mt-5"
              :percentage="uiState.importLoaderProgress"
              :show-text="false"
            />
            <div class="text-primary font-italic font-11 text-center mt-4">
              Importing {{ listingsRef.length - existingListings.length }} listings
            </div>
          </div>
          <div class="listings">
            <div
              v-for="(listing,index) in listingsRef"
              :key="index"
              class="listing-item row gutter-0.5 justify-content-between align-items-center font-15"
            >
              <div :class="listing.property_id ? 'col-8' : 'col-12'">
                {{ listing.address }}
              </div>
              <div
                v-if="listing.property_id"
                class="col-4 font-11 font-italic"
              >
                <span class="active-icon bg-primary rounded-circle mr-1">
                  <i class="sdicon-check text-white" />
                </span>
                Already on Showdigs
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="uiState.flow===uiFlow.ERROR"
        class="text-center position-relative"
      >
        <h2>Could not import</h2>
        <p>
          We couldn't find an AppFolio account named {{ identifier }}.
          Please verify that this name is correct, and that it is an AppFolio subdomain, and try again.
        </p>
      </div>
    </div>
    <span
      v-if="uiState.flow !== uiFlow.ERROR"
      slot="footer"
      class="row justify-content-center"
    >
      <div class="col-6 col-md-4">
        <ElButton
          class="w-100"
          size="medium"
          @click="close"
        >
          Cancel
        </ElButton>
      </div>
      <div class="col-6 col-md-4">
        <ElButton
          class="w-100"
          size="medium"
          type="primary"
          :disabled="!identifier || uiState.flow === uiFlow.IMPORT"
          @click="next"
        >
          <span v-if="[uiFlow.INIT, uiFlow.IDENTIFIER].includes(uiState.flow)">Next</span>
          <span v-if="[uiFlow.REVIEW, uiFlow.IMPORT].includes(uiState.flow)">{{ listingsRef && listingsRef.length ? 'Import' : 'Enable Sync' }}</span>
        </ElButton>
      </div>
    </span>
  </ElDialog>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api';

import Plan from '@/constants/Plan';
import { BusinessSourceTypes } from '../../constants/BusinessSource';

const uiFlow = {
  INIT: 'init',
  IDENTIFIER: 'identifier',
  SCRAPE: 'scrape',
  REVIEW: 'review',
  NO_LISTINGS: 'no_listings',
  IMPORT: 'import',
  ERROR: 'error',
};
export default {
  name: 'ImportPropertiesDialog',
  components: {},
  props: {
    businessSource: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const plan = store.getters['Auth/plan'];
    const isBasicPlan = plan.name === Plan.BASIC;
    const uiState = reactive({
      flow: uiFlow.INIT,
      importLoaderProgress: 0,
    });
    const businessSourceRef = ref(props.businessSource);
    const identifier = ref(null);
    const listingsRef = ref(null);
    const existingListings = computed(computeExistingListings);

    init();
    return {
      uiFlow,
      uiState,
      close,
      identifier,
      next,
      listingsRef,
      existingListings,
      isBasicPlan,
    };

    async function init() {
      try {
        if (!businessSourceRef.value) {
          const businessSourceRow = await context.root.$store.dispatch('BusinessSource/index');
          if (businessSourceRow && businessSourceRow.business_source_identifier) {
            businessSourceRef.value = businessSourceRow;
          }
        }
        identifier.value = businessSourceRef.value.business_source_identifier;
      } catch (e) {}
      uiState.flow = uiFlow.IDENTIFIER;
    }

    async function scrape() {
      uiState.flow = uiFlow.SCRAPE;
      try {
        // check if identifier needs to be created or updated
        if (businessSourceRef.value) {
          if (identifier.value !== businessSourceRef.value.business_source_identifier) {
            await store.dispatch('BusinessSource/update', { id: businessSourceRef.value.id, business_source_identifier: identifier.value });
            businessSourceRef.value.business_source_identifier = identifier.value;
          }
        } else {
          businessSourceRef.value = await store.dispatch('BusinessSource/store', {
            business_source_identifier: identifier.value,
          });
        }

        // get listings
        if (businessSourceRef.value) {
          const listings = await store.dispatch('Listing/index');
          listingsRef.value = listings.sort((a, b) => {
            if (a.address < b.address) { return -1; }
            if (a.address > b.address) { return 1; }
            return 0;
          });
        }

        uiState.flow = uiFlow.REVIEW;
      } catch (error) {
        if (error.message) {
          uiState.flow = uiFlow.ERROR;
        }
      }
    }

    async function importListings() {
      uiState.flow = uiFlow.IMPORT;
      const progressLoaderInterval = setInterval(() => {
        if (uiState.importLoaderProgress === 100) {
          uiState.importLoaderProgress = 30;
        } else {
          uiState.importLoaderProgress += 1;
        }
      }, 100);
      try {
        const response = await store.dispatch('Property/syncAll');
        clearInterval(progressLoaderInterval);
        uiState.importLoaderProgress = 0;
        close({ status: 'success', data: response });
      } catch (error) {
        uiState.flow = uiFlow.ERROR;
        clearInterval(progressLoaderInterval);
        uiState.importLoaderProgress = 0;
      }
    }

    function next() {
      if (uiState.flow === uiFlow.IDENTIFIER) {
        scrape();
      }
      if (uiState.flow === uiFlow.REVIEW) {
        importListings();
      }
    }

    function close(emit) {
      context.emit('close', emit);
    }

    function computeExistingListings() {
      if (!listingsRef.value) {
        return null;
      }
      return listingsRef.value.filter((listing) => listing.property_id);
    }
  },
};
</script>
<style lang="scss">
    #import-appfolio-dialog {
        .el-dialog {
            min-height: 362px;
        }
      &.short-dialog {
        .el-dialog {
          min-height: 187px;
        }
      }
        .loader-wrapper {
            position: absolute;
            top:0;
            left: 0;
            background: $white;
            width: 100%;
            height: 100%;
            z-index: 1000;
            border-radius: $app-border-radius;
        }

        .review-success-icon {
            width: 3.5rem;
            height: 3.5rem;
        }
        .listings{
            height: 12rem;
            overflow-y:auto;
            overflow-x: hidden;
            .listing-item {
                color: gray-color('dark');
                font-size: $--font-size-base;
                padding: 0.5rem;
                border-bottom: 1px solid gray-color('light');
                .active-icon {
                    width: $--font-size-base;
                    height: $--font-size-base;
                    display: inline-block;
                    i {
                        display: block;
                        margin-top: -2px;
                        margin-left: -1px;
                        font-size: $--font-size-small;
                        font-weight: $strong;
                    }
                }
            }
        }
        .import-loader {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 0;
            left: 0;
            background-color: rgba(255,255,255, 0.85);
        }
    }
</style>
