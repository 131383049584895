<template>
  <ElForm
    ref="form"
    :model="payload"
  >
    <SdFloatLabel>
      <ElFormItem class="mb-4">
        <ElInput
          v-model="payload.clientId"
          placeholder="Client id"
          @change="changeClientId"
        />
      </ElFormItem>
    </SdFloatLabel>
    <SdFloatLabel>
      <ElFormItem class="mb-4">
        <ElInput
          v-model="payload.secret"
          placeholder="Secret"
          @change="changeClientId"
        />
      </ElFormItem>
    </SdFloatLabel>
  </ElForm>
</template>

<script>
import SdFloatLabel from '@/components/common/form/SdFloatLabel.vue';
import { ref } from '@vue/composition-api';

export default {
  name: 'BuildiumIntegration',
  components: {
    SdFloatLabel,
  },
  props: {
    sourceType: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const payload = ref({
      clientId: '',
      secret: '',
      integration_type: props.sourceType,
    });
    return {
      payload,
      changeClientId,
    };

    function changeClientId() {
      context.emit('update-payload', payload.value);
    }
  },
};
</script>
