const IdentityVerificationTypes = {
  TYPE_ALL: 'all',
  TYPE_SELF_SHOWING: 'self-showing',
};

const IncomeVoucherTypes = {
  DEFAULT: null,
  DEDUCTIBLE: 'deductible',
  QUALIFIES: 'qualifies',
};

const IdentityVerificationOptions = [
  {
    label: 'Default to enabled for all showing types',
    value: IdentityVerificationTypes.TYPE_ALL,
  },
  {
    label: 'Default to enabled for self-showings only',
    value: IdentityVerificationTypes.TYPE_SELF_SHOWING,
  },
  {
    label: 'Always default to disabled',
    value: null,
  },
];

const IncomeVoucherOptions = [
  {
    label: 'Use the entire rent',
    value: IncomeVoucherTypes.DEFAULT,
  },
  {
    label: 'Use only the rent the prospect is responsible for',
    value: IncomeVoucherTypes.DEDUCTIBLE,
  },
  {
    label: 'Automatically qualify prospects with vouchers',
    value: IncomeVoucherTypes.QUALIFIES,
  },
];

export {
  IdentityVerificationOptions,
  IdentityVerificationTypes,
  IncomeVoucherTypes,
  IncomeVoucherOptions,
};
