<template>
  <div class="d-flex">
    <ElFormItem
      :rules="validationRules.week_day"
      :prop="`${validationProp}.week_day`"
    >
      <SdFloatLabel label="Day">
        <ElSelect
          v-model="showingWindowPayload.week_day"
          placeholder="Day"
        >
          <ElOption
            v-for="weekday in weekdaysOptions"
            :key="weekday.value"
            :label="weekday.label"
            :value="weekday.value"
          />
        </ElSelect>
      </SdFloatLabel>
    </ElFormItem>
    <ElFormItem
      ref="fromHour"
      :rules="validationRules.from_hour"
      :prop="`${validationProp}.from_hour`"
      class="ml-2"
    >
      <SdFloatLabel label="From">
        <ElSelect
          v-model="showingWindowPayload.from_hour"
          class="select-dropdown-height"
          placeholder="From"
        >
          <ElOption
            v-for="hourOption in hoursOptions[showingWindowPayload.week_day]"
            :key="hourOption.value"
            :label="hourOption.label"
            :value="hourOption.value"
          />
        </ElSelect>
      </SdFloatLabel>
    </ElFormItem>
    <ElFormItem
      ref="toHour"
      :rules="validationRules.to_hour"
      :prop="`${validationProp}.to_hour`"
      class="ml-2"
    >
      <SdFloatLabel label="To">
        <ElSelect
          v-model="showingWindowPayload.to_hour"
          class="select-dropdown-height"
          placeholder="To"
        >
          <ElOption
            v-for="hourOption in hoursOptions[showingWindowPayload.week_day]"
            :key="hourOption.value"
            :label="hourOption.label"
            :value="hourOption.value"
          />
        </ElSelect>
      </SdFloatLabel>
    </ElFormItem>
    <ElFormItem
      :prop="`${validationProp}.assignee_id`"
      class="ml-2"
    >
      <SdFloatLabel
        label="Assignee"
      >
        <ElSelect
          v-model="showingWindowPayload.assignee_id"
          placeholder="Assignee"
        >
          <ElOption
            v-for="assignee in assigneesOptions"
            :key="assignee.value"
            :disabled="assignee.disabled"
            :label="assignee.label"
            :value="assignee.value"
          >
            {{ assignee.label }}
          </ElOption>
        </ElSelect>
      </SdFloatLabel>
    </ElFormItem>
  </div>
</template>

<script>
import { computed, reactive, watch } from '@vue/composition-api';
import { createHourValidator } from '@/utils/ShowingWindowUtil';
import { createEnumValidator } from '@/utils/FormValidationUtil';

export default {
  name: 'ShowingWindow',
  props: {
    showingWindow: {
      type: Object,
      required: true,
    },
    showingWindows: {
      type: Array,
      required: true,
    },
    validationProp: {
      type: String,
      required: true,
    },
    weekdaysOptions: {
      type: Array,
      required: true,
    },
    hoursOptions: {
      type: Array,
      required: true,
    },
    assigneesOptions: {
      type: Array,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const showingWindowPayload = reactive({ ...props.showingWindow });

    watch(showingWindowPayload, () => {
      context.emit('update', showingWindowPayload);
    }, { deep: true });

    const validationRules = computed(() => ({
            week_day: createEnumValidator('Day', props.weekdaysOptions.map((wd) => wd.value)),
            from_hour: createHourValidator('From', props.hoursOptions, props.showingWindow, props.showingWindows),
            to_hour: createHourValidator('To', props.hoursOptions, props.showingWindow, props.showingWindows),
          }));

    return {
      showingWindowPayload,
      validationRules,
    };
  },
};
</script>

<style scoped lang="scss">

.showing-window {

  .select-dropdown-height {
    // Fix for internal bug of the 'ElSelect' component,
    // see @link https://github.com/ElemeFE/element/issues/15818
    ::v-deep {
      .el-input__inner {
        height: 56px !important;
      }
    }
  }

  .disabled {
    color: gray-color('dark');
  }

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  > div:not(:first-of-type) {
    margin-left: 0.75rem;
  }

  > div:not(.delete) {
    flex: 1;
  }
}
</style>
