const AppfolioConditionReportUploadTypes = {
  TYPE_AUTOMATIC: 'automatic',
  TYPE_DISABLED: 'disabled',
};

const AppfolioConditionReportUploadOptions = [
  {
    label: 'Automatic',
    value: AppfolioConditionReportUploadTypes.TYPE_AUTOMATIC,
    description: '<b>Automatic:</b> Condition reports will be uploaded into AppFolio once they are completed. All reports will be attached to the relevant property or unit, and periodic reports will also be attached to the tenant\'s profile.',
  },
  {
    label: 'Disabled',
    value: AppfolioConditionReportUploadTypes.TYPE_DISABLED,
    description: '<b>Disabled</b>: Conditions reports will not be uploaded into AppFolio.',
  },
];

export { AppfolioConditionReportUploadOptions, AppfolioConditionReportUploadTypes };
