<template>
  <ElDialog
    id="self-showing-disable-dialog"
    :center="true"
    :show-close="false"
    :close-on-click-modal="false"
    :visible.sync="uiFlags.isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
  >
    <div class="text-center justify-content-center">
      <div class="font-21 font-weight-strong my-3 text-center">
        Self-showing properties still active
      </div>
      <div class="text-center">
        You are currently showing {{ uiFlags.activeCodeboxProperties.length }}
        {{ $pluralize('property', uiFlags.activeCodeboxProperties.length) }}
        using self-showing codeboxes.
        Stop these showings to allow disabling the codebox integration.
      </div>
      <div
        v-for="(activeCodeboxProperty, key) in uiFlags.activeCodeboxProperties"
        :key="key"
        class="ml-4"
      >
        <li class="bullet text-left mt-3">
          {{ activeCodeboxProperty.address }}
        </li>
      </div>
      <ElButton
        type="primary"
        size="medium"
        class="justify-center mb-3 mt-5"
        @click="uiFlags.isVisible = false;"
      >
        Ok
      </ElButton>
    </div>
  </ElDialog>
</template>

<script>
import { reactive } from '@vue/composition-api';
import CompletedMarker from '@/assets/icons/list-mark.svg';

export default {
  name: 'SelfShowingDisableDialog',
  setup(props, context) {
    const uiFlags = reactive({
      isVisible: false,
      activeCodeboxProperties: [],
      loaded: false,
    });

    return {
      uiFlags,
      open,
    };

    function open(activeCodeboxProperties) {
      uiFlags.activeCodeboxProperties = activeCodeboxProperties;
      uiFlags.isVisible = true;
    }
  },
};
</script>

<style lang="scss">
</style>
