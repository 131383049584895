<template>
  <div class="add-ons-picture d-flex mt-4">
    <SdArt
      class="align-self-center"
      :art="art"
      :size="size"
      :height="heightComputed"
    />
    <p class="mt-2 text-gray-dark font-13">
      {{ description }}
    </p>
  </div>
</template>

<script>

import SdArt from '@/components/common/SdArt';

export default {
  name: 'AddOnArt',
  components: {
    SdArt,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
    art: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: null,
    },
  },
  computed: {
    heightComputed() {
      return this.height || this.size;
    },
  },
};
</script>
<style lang="scss">
.add-ons-picture {
  flex-direction: column;
  width: 140px;
  p {
    text-align: center;
  }
}
</style>
