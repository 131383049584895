<template>
  <div>
    <el-alert
      v-if="token.tokenValue"
      type="warning"
      show-icon
      class="text-left p-4"
      :closable="false"
    >
      <div class="font-15 font-weight-bold">
        Copy token
      </div>
      <div class="mt-1">
        Don’t leave without copying the token first. This is the only time you will see the full access token!
      </div>
    </el-alert>
    <div class="main-token-row">
      <div
        v-if="token.tokenValue"
        class="token"
      >
        <ElInput
          id="token-input"
          ref="token"
          v-model="token.tokenValue"
          readonly
          class="token-input px-0"
          type="text"
        />
        <div
          class="copy-token"
          @click="copyToken"
        >
          <i class="sdicon-link" />
          <span>
            Copy Access Token
          </span>
        </div>
        <ElDropdown
          v-if="$viewport.lt.md"
          class="dropdown-container"
          placement="bottom-start"
          trigger="click"
        >
          <div>
            <i class="sdicon-more-vert font-24 pointer" />
          </div>
          <ElDropdownMenu class="no-popper">
            <ElDropdownItem
              class="text-primary"
              @click.native="openRevokeTokenDialog"
            >
              Revoke Access
            </ElDropdownItem>
          </ElDropdownMenu>
        </ElDropdown>
      </div>
      <div
        v-else
        class="token-created-at"
      >
        <ElInput
          v-model="createdAt"
          readonly
          class="token-input px-0"
          type="text"
        />
        <ElDropdown
          v-if="$viewport.lt.md"
          class="dropdown-container"
          placement="bottom-start"
          trigger="click"
        >
          <div>
            <i class="sdicon-more-vert font-24 pointer" />
          </div>
          <ElDropdownMenu class="no-popper">
            <ElDropdownItem
              class="text-primary"
              @click.native="openRevokeTokenDialog"
            >
              Revoke Access
            </ElDropdownItem>
          </ElDropdownMenu>
        </ElDropdown>
      </div>
      <div
        v-if="!$viewport.lt.md"
        class="revoke-button"
      >
        <ElButton
          size="medium"
          @click="openRevokeTokenDialog"
        >
          Revoke Access
        </ElButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api/dist/vue-composition-api';
import { showSuccessNotify } from '@/utils/NotifyUtil';

export default {
  name: 'BusinessSettingsTokenField',
  props: {
    token: {
      type: Object,
      default: () => { },
    },
  },
  setup(props, context) {
    const isVisibleRef = ref(false);
    const createdAt = tokenCreatedAt();
    return {
      openRevokeTokenDialog,
      isVisibleRef,
      copyToken,
      createdAt,
    };

    function openRevokeTokenDialog() {
      context.emit('open-revoke-token-dialog');
    }

    function tokenCreatedAt() {
      if (!props.token.tokenCreatedAt) {
        return '';
      }
      const momentDate = context.root.$momentUtil(props.token.tokenCreatedAt, context.root.$momentUtil.tz.guess());
      return `Generated on ${momentDate.toDisplayFormatFullMonthDayAndYearSt()}`;
    }

    function copyToken() {
      const tokenInput = document.querySelector('#token-input');
      tokenInput.select();
      tokenInput.setSelectionRange(0, 99999);
      document.execCommand('copy');
      const selectedElement = document.getSelection();
      selectedElement.removeAllRanges();
      showSuccessNotify(context, 'The Access Token key has been successfully copied.');
    }
  },
};
</script>
<style lang="scss">
.dropdown-container {
  color: #7A94A5;
  height: 39px;
  border-radius: 5px;
}

.main-token-row {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .revoke-button {
    flex-grow: 4;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    position: relative;
    left: -4px;
  }

  .token-created-at {
    width: 100%;
    flex-grow: 1;
    background: #EBF4FF;
    display: flex;
    border-radius: 5px;

    .dropdown-container {
      align-items: center;
    }

    .token-input {
      input {
        background: #EBF4FF;
        height: 39px;
      }

      @media (max-width: 520px) {
        font-size: 1rem;
        padding-left: 5px;
        padding-right: 0;
      }
    }
  }
}

.token {
  display: flex;
  width: 100%;
  flex-grow: 8;
  background: #EBF4FF;
  border-radius: $app-border-radius;
  align-items: center;
  font-size: $--font-size-base;
  transition: $app-transition;
  height: 39px;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 0;

  @media (max-width: 520px) {
    padding-right: 0;
    padding-left: 5px
  }

  .token-input {
    max-width: 380px;

    @media (max-width: 520px) {
      max-width: 140px;
    }

    font-size: 0.9375rem;

    input {
      height: 29.25px;
      color: #7A94A5;
      background-color: theme-color('light-grey');
      @media (max-width: 520px) {
        padding: 0 5px;
        font-size: 12px;
      }
    }
  }

  .copy-token {
    cursor: pointer;
    font-size: 0.9375rem;
    padding-right: 15px;

    @include media-breakpoint-down(md) {
      min-width: 160px;
      padding-right: 0;
    }
    @media (max-width: 520px) {
      font-size: 0.7rem;
      padding-left: 15px;
    }

    &:focus {
      outline: 0;
    }

    i {
      color: #819CAE;
    }

    span {
      color: $primary;

      &:hover {
        color: #126AD8;
      }
    }
  }
}
</style>
