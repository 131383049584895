<template>
  <ElDialog
    id="properties-member-transfer-dialog"
    :center="true"
    :show-close="false"
    :close-on-click-modal="false"
    :visible.sync="uiFlags.isVisible"
    :width="$viewport.lt.md ? '100vw' : '500px'"
  >
    <div class="text-center justify-content-center">
      <template v-if="!uiFlags.completed">
        <div class="font-21 font-weight-strong my-3 text-center">
          {{ `Account deletion - ${originalOwner.full_name}` }}
        </div>
        <div class="text-center">
          {{ originalOwner.full_name }} has assignments associated with:
        </div>
        <div
          v-for="(property, key) in managedProperties"
          :key="key"
          class="properties-holder overflow-auto ml-4"
        >
          <li class="bullet text-left mt-3">
            <span class="font-weight-strong">
              {{ property }}
            </span>
          </li>
        </div>
        <div class="text-center my-3">
          In order to proceed with their account deletion, please choose someone to transfer these assignments to:
        </div>
        <ElSelect
          v-model="selectedListingOwnerId"
          placeholder="Listing owner"
          value-key="value"
        >
          <ElOption
            v-for="listingOwner in verifiedListingOwners"
            :key="listingOwner.value"
            :label="listingOwner.label"
            :value="listingOwner.value"
          />
        </ElSelect>
        <div
          slot="footer"
          class="mt-4 row gutter-1 justify-content-center"
        >
          <div class="col-md-3 col">
            <ElButton
              class="w-100"
              size="medium"
              @click="uiFlags.isVisible = false"
            >
              Cancel
            </ElButton>
          </div>
          <div class="col-md-9 col">
            <ElButton
              class="w-100"
              size="medium"
              type="primary"
              :disabled="!selectedListingOwnerId"
              :loading="uiFlags.isButtonLoading"
              @click="transferAndDelete"
            >
              Transfer and delete account
            </ElButton>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          {{ uiFlags.finalMessage }}
        </div>
        <ElButton
          type="primary"
          size="medium"
          class="justify-center mb-3 mt-5"
          @click="closeAndRefresh"
        >
          Close
        </ElButton>
      </template>
    </div>
  </ElDialog>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import { showErrorNotify } from '@/utils/NotifyUtil';
import pluralize from 'pluralize';

export default {
  name: 'PropertiesMemberTransferDialog',
  setup(props, context) {
    const selectedListingOwnerId = ref(null);
    const managedProperties = ref([]);
    const verifiedListingOwners = ref([]);
    const originalOwner = ref({});
    const successData = ref({});
    const uiFlags = reactive({
      isVisible: false,
      completed: false,
      isButtonLoading: false,
      finalMessage: '',
    });

    return {
      uiFlags,
      selectedListingOwnerId,
      verifiedListingOwners,
      originalOwner,
      managedProperties,
      transferAndDelete,
      open,
      closeAndRefresh,
    };

    async function open(owner, properties) {
      selectedListingOwnerId.value = null;
      uiFlags.completed = false;
      managedProperties.value = properties;
      const data = await context.root.$store.dispatch('Auth/getTeamMembers', {
        paginate: 0,
        'scopes[verified]': 1,
      });
      verifiedListingOwners.value = data.filter((user) => user.id !== owner.id)
        .map((user) => ({
          value: user.id,
          email: user.email,
          label: user.full_name,
        }));
      originalOwner.value = owner;

      uiFlags.isVisible = true;
    }

    async function transferAndDelete() {
      uiFlags.isButtonLoading = true;
      try {
        await context.root.$store.dispatch('Business/removeMember', {
          userId: originalOwner.value.id,
          destinationListingManagerId: selectedListingOwnerId.value,
        });
        const listingOwner = verifiedListingOwners.value.find((owner) => owner.value === selectedListingOwnerId.value);
        uiFlags.finalMessage = `${originalOwner.value.full_name} removed from this business account and `
          + `${managedProperties.value.length} ${pluralize('properties', managedProperties.value.length)} `
          + `transferred to ${listingOwner.label}`;
        uiFlags.completed = true;
      } catch (error) {
        uiFlags.isVisible = false;
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.isButtonLoading = false;
      }
    }

    function closeAndRefresh() {
      uiFlags.isVisible = false;
      context.emit('reload-data');
    }
  },
};
</script>

<style lang="scss">
#properties-member-transfer-dialog {
  .properties-holder {
    max-height: 20vh;
  }

  @include media-breakpoint-down(md) {
    .el-dialog {
      margin-top: 0vh !important;
      height: 100vh;
    }
  }
}
</style>
