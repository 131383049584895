<template>
  <div>
    <h3 class="font-21">
      Schedule templates
    </h3>
    <p>
      These templates will be available during the activation process, to allow you to quickly set team showing
      availability.
    </p>

    <Draggable
      v-loading="uiFlags.isProcessing"
      :list="scheduleTemplates"
      ghost-class="ghost"
      handle=".handle"
      tag="ul"
      class="list-unstyled"
      @change="handleSort"
    >
      <li
        v-for="scheduleTemplate in scheduleTemplates"
        :key="scheduleTemplate.id"
        class="schedule-template-item d-flex align-items-center p-2 border-bottom"
        :class="{ 'is-editing': selectedTemplate && selectedTemplate.id === scheduleTemplate.id}"
      >
        <i class="sdicon-draggable pointer" />
        <div class="ml-2 mr-auto align-self-baseline">
          <div class="font-17">
            {{ scheduleTemplate.name }}
          </div>
          <div class="text-gray font-14 ellipsis">
            API ID: {{ scheduleTemplate.uuid }}
            <SdCopyToClipboard
              :copy="scheduleTemplate.uuid"
              icon="sdicon-copy"
              icon-copied="sdicon-check"
              text="Copy"
              text-copied="Copied"
              copied-class="text-success"
            />
          </div>
        </div>
        <div class="d-flex align-items-center">
          <ElButton
            :disabled="uiFlags.isEditing"
            type="text"
            class="p-0 text-dark"
            @click.stop="selectTemplate(scheduleTemplate)"
          >
            <i class="sdicon-pencil-edit font-17" />
          </ElButton>
          <ElButton
            :disabled="uiFlags.isEditing"
            type="text"
            class="delete-button p-0"
            @click.stop="showConfirmDelete(scheduleTemplate)"
          >
            <i class="sdicon-trash font-21" />
          </ElButton>
        </div>
      </li>
    </Draggable>

    <ElButton
      v-if="selectedTemplate === null"
      size="small"
      @click="addTemplate"
    >
      <i class="sdicon-plus" /> Add template
    </ElButton>

    <template
      v-if="selectedTemplate"
    >
      <ElForm
        ref="form"
        :model="selectedTemplate"
      >
        <ScheduleTemplate
          :key="selectedTemplate.id || 'new'"
          :schedule-template="selectedTemplate"
          :assignee-options="assigneeOptions"
          :hours-options="operatingHourOptions"
          :weekdays-options="dayOptions"
          @update="handleUpdate"
          @save="handleSave"
        />
      </ElForm>
      <div class="d-flex justify-content-end">
        <ElButton
          size="medium"
          :loading="uiFlags.isProcessing"
          @click="handleDiscard"
        >
          Discard
        </ElButton>

        <ElButton
          size="medium"
          type="primary"
          :disabled="!uiFlags.isDirty"
          :loading="uiFlags.isProcessing"
          class="ml-3"
          @click="handleSave"
        >
          Save Template
        </ElButton>
      </div>
    </template>
  </div>
</template>

<script>

import {
  computed, onMounted, reactive, ref,
} from '@vue/composition-api';
import { MessageBox } from 'element-ui';
import Draggable from 'vuedraggable';
import ScheduleTemplate from '@/components/settings/ScheduleTemplates/ScheduleTemplate.vue';
import {
  getAssigneesOptions, getShowdigsAssigneeUser,
  getShowingWindowsFromAvailabilityDays,
  getWeekdaysOptions,
} from '@/utils/ShowingWindowUtil';
import cloneDeep from 'lodash.clonedeep';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import isEqual from 'lodash.isequal';
import SdCopyToClipboard from '@/components/common/SdCopyToClipboard.vue';

export default {
  name: 'BusinessSettingScheduleTemplates',
  components: { SdCopyToClipboard, ScheduleTemplate, Draggable },
  props: {},
  setup(props, context) {
    const store = context.root.$store;
    // Templates state
    const scheduleTemplates = ref([]);
    // Selected template
    const selectedTemplate = ref(null);
    const originalSelectedTemplate = ref(null);
    // Showing window options
    const defaultSchedule = ref([]);
    const assigneeOptions = ref([]);
    const dayOptions = ref([]);
    const operatingHourOptions = ref([]);
    // Flags
    const isDirty = computed(() => !isEqual(selectedTemplate.value, originalSelectedTemplate.value));
    const isEditing = computed(() => selectedTemplate.value !== null);
    const uiFlags = reactive({ isProcessing: true, isEditing, isDirty });

    const getScheduleTemplateRequest = (templateResponse) => ({
        id: templateResponse.id,
        name: templateResponse.name,
        order: templateResponse.order,
        showing_windows: templateResponse.showing_windows,
      });

    const selectTemplate = (template) => {
      selectedTemplate.value = cloneDeep(getScheduleTemplateRequest(template));
      originalSelectedTemplate.value = cloneDeep(getScheduleTemplateRequest(template));
    };

    const unselectTemplate = () => {
      selectedTemplate.value = null;
      originalSelectedTemplate.value = null;
    };

    const addTemplate = () => {
      const newTemplate = {
        id: null,
        name: '',
        order: scheduleTemplates.value.length + 1,
        showing_windows: [...defaultSchedule.value],
      };

      selectTemplate(newTemplate);
    };

    const handleSort = async () => {
      const sortedTemplates = scheduleTemplates.value.map((template, index) => ({
        id: template.id,
        order: index + 1,
      }));

      await store.dispatch('AvailabilityDay/sortScheduleTemplates', { schedule_templates: sortedTemplates });
    };

    const handleUpdate = (template) => {
      selectedTemplate.value = template;
    };

    const handleSave = async () => {
      const store = context.root.$store;
      await context.refs.form.clearValidate();

      const validated = await context.refs.form.validate().catch((_) => {});

      if (!validated) {
        return;
      }

      try {
        uiFlags.isProcessing = true;

        if (selectedTemplate.value.id === null) {
          await store.dispatch('AvailabilityDay/saveScheduleTemplate', selectedTemplate.value);
        } else {
          await store.dispatch('AvailabilityDay/updateScheduleTemplate', selectedTemplate.value);
        }

        unselectTemplate();
        await fetchScheduleTemplates();

        showSuccessNotify(context, 'Schedule template saved successfully');
      } catch (error) {
        showErrorNotify(context, error);
      } finally {
        uiFlags.isProcessing = false;
      }
    };

    const handleDiscard = () => {
      selectedTemplate.value = null;
    };

    const showConfirmDelete = (scheduleTemplate) => {
      MessageBox.confirm(
        `Are you sure you want to delete<br><span class="font-weight-strong">${scheduleTemplate.name}</span>?`
          + '<br>Deleting a template will not affect <br>the schedule for any active showings.',
          'Delete schedule template',
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'Cancel',
            center: true,
            type: 'error',
            lockScroll: true,
            callback: async (action) => {
              if (action === 'confirm') {
                await store.dispatch('AvailabilityDay/deleteScheduleTemplate', scheduleTemplate.id);
                await fetchScheduleTemplates();
              }
            },
          },
      );
    };

    const fetchScheduleTemplates = async () => {
      try {
        uiFlags.isProcessing = true;
        scheduleTemplates.value = await store.dispatch('AvailabilityDay/getScheduleTemplates');
      } catch (error) {
        showErrorNotify(context, error);
      } finally {
        uiFlags.isProcessing = false;
      }
    };

    onMounted(async () => {
      const [baselineSchedule, assignees] = await Promise.all([
        context.root.$store.dispatch('AvailabilityDay/platform'),
        context.root.$store.dispatch('Auth/getTeamMembers', { paginate: 0 }),
      ]);

      defaultSchedule.value = baselineSchedule.map((showingWindow) => ({
        ...showingWindow,
        assignee_id: null,
      }));

      assigneeOptions.value = getAssigneesOptions([getShowdigsAssigneeUser(null), ...assignees]);
      dayOptions.value = getWeekdaysOptions();
      operatingHourOptions.value = getShowingWindowsFromAvailabilityDays(baselineSchedule);

      await fetchScheduleTemplates();
    });

    return {
      scheduleTemplates,
      selectedTemplate,
      assigneeOptions,
      dayOptions,
      operatingHourOptions,
      uiFlags,
      selectTemplate,
      addTemplate,
      handleSort,
      handleUpdate,
      handleSave,
      handleDiscard,
      showConfirmDelete,
    };
  },
};
</script>

<style scoped lang="scss">
.schedule-template-item {
  border-left: 3px solid transparent;

  &.is-editing {
    color: gray-color("darker");
    background: #ebf4ff;
    border-left-color: theme-color("primary");
  }

  &.ghost{
    background: #ebf4ff;
  }
}

.delete-button:not([disabled]) {
  color: $--color-danger;
}

::v-deep .el-select.template-selector .el-input input {
  border-radius: $--border-radius-base 0 0 $--border-radius-base;
}
</style>
