<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="d-flex mb-3 justify-content-between align-items-center">
        <h4 class="mb-0 font-17">
          Zillow Network
        </h4>
        <SdSwitchSaved
          :is-saved="uiFlags.showSaved"
          @hide-text="uiFlags.showSaved = false"
        >
          <ElSwitch
            v-model="isZillowSyndicationEnabled"
            class="col-auto d-block"
            :disabled="disabled || uiFlags.isLoading"
            :width="44"
            @change="updateSyndicationSettings"
          />
        </SdSwitchSaved>
      </div>
      <p class="font-15">
        Publish your property listings to Zillow, Trulia, and HotPads
        when you start showings.
      </p>
    </div>
  </div>
</template>

<script>

import { reactive, ref } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import SdSwitchSaved from '@/components/common/form/SdSwitchSaved.vue';

export default {
  name: 'ZillowSettingsBlock',
  components: {
    SdSwitchSaved,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, context) {
    const uiFlags = reactive({ showDialog: false, showSaved: false, isLoading: false });
    const isZillowSyndicationEnabled = ref(props.business.zillow_syndication_enabled_at !== null);

    async function updateSyndicationSettings() {
      const action = isZillowSyndicationEnabled.value ? 'Enabled' : 'Disabled';

      try {
        uiFlags.isLoading = true;

        await context.root.$store
          .dispatch('BusinessSettings/updateAndCommitPayload', {
            meta: 'syndication.zillow',
            payload: {
              zillow: {
                is_zillow_syndication_enabled: isZillowSyndicationEnabled.value,
              },
            },
          });

        showSuccessNotify(
          context,
          `Zillow syndication has been <span class="font-weight-strong underline">${action}</span>.`,
          { dangerouslyUseHTMLString: true },
        );

        uiFlags.showSaved = true;
      } catch (error) {
        showErrorNotify(context, 'Error while trying to update Zillow syndication settings', error.message);
      } finally {
        uiFlags.isLoading = false;
      }
    }

    return {
      uiFlags,
      isZillowSyndicationEnabled,
      updateSyndicationSettings,
    };
  },
};
</script>
