<template>
  <div class="row no-gutters d-block">
    <div class="col-8">
      <ElDivider class="mt-2 mb-5" />
      <div class="ai-leasing-assistant-container">
        <div class="d-flex justify-content-between align-items-baseline">
          <h3 class="font-21 position-relative">
            <i class="sdicon-phone-ai font-21" />
            <span>AI Leasing Assistant</span>
          </h3>
          <p
            :class="{
              'text-muted': !uiFlags.isAiLeasingAgentEnabled,
              'text-success': uiFlags.isAiLeasingAgentEnabled,
            }"
          >
            {{ uiFlags.isAiLeasingAgentEnabled ? 'Enabled' : 'Disabled' }}
          </p>
        </div>
        <p class="font-15 mt-2">
          Answer questions from prospective renters without lifting a finger. Get a single phone number for your entire business,
          and let our AI assistant answer calls, and convert your leads.
        </p>
        <div class="mt-2 font-18 text-center">
          <div
            v-if="uiFlags.isAiLeasingAgentEnabled && phoneNumberFormatted"
          >
            <span class="font-weight-bold">
              Your Number:
            </span>
            <span class="ml-1">{{ phoneNumberFormatted }}</span>
            <SdCopyToClipboard
              class="ml-2"
              :copy="copiedPhoneNumber"
              icon="sdicon-copy"
              text="Copy"
              text-copied="Copied"
              input-id="copy-phone-number"
              text-css-class="copy-phone-number"
            />
          </div>
          <p
            v-else
            class="font-weight-bold"
          >
            <a :href="`mailto:${Showdigs.SUPPORT_EMAIL}`">
              <i class="sdicon-mail position-relative font-size-inherit" />
              Email</a> Us today to learn more
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SdCopyToClipboard from '@/components/common/SdCopyToClipboard';
import { reactive, ref, computed } from '@vue/composition-api';
import Showdigs from '@/constants/Showdigs';

export default {
  name: 'BusinessSettingsAddonsTab',
  components: {
    SdCopyToClipboard,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
    business: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const copiedPhoneNumber = ref(props.business.ai_leasing_agent_phone_number?.phoneNumber ?? '');
    const phoneNumberFormatted = computed(computePhoneNumber);

    const uiFlags = reactive({
      loading: false,
      isAiLeasingAgentEnabled: Boolean(props.business.ai_leasing_agent_enabled_at),
    });

    return {
      uiFlags,
      phoneNumberFormatted,
      copiedPhoneNumber,
      Showdigs,
      hasChanges,
    };
    function computePhoneNumber() {
      return props.business.ai_leasing_agent_phone_number?.formattedPhoneNumber;
    }

    function hasChanges(originalPayload) {
      return false;
    }
  },
};
</script>

<style lang="scss" scoped>

.ai-leasing-assistant-container {
  h3 {
    padding-left: 35px;

    .sdicon-phone-ai {
      position: absolute;
      top: -2px;
      left: 0;
    }
  }
}

::v-deep .copy-wrapper {
  font-size: 13px;
  color: gray-color('dark');

  .copy-phone-number {
    display: inline-block;
  }

  .sdicon-copy {
    font-size: inherit;
    color: inherit;
  }

}
</style>
