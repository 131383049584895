<template>
  <div
    class="col-12 pt-1 text-secondary"
  >
    This integration is enabled through AppFolio. Simply setup a Showdigs connection from the AppFolio Stack Marketplace.
    <a
      target="_blank"
      :href="`https://help.showdigs.com/kb/guide/en/pms-integration-101-WlfHYTK22C`"
    >Learn More </a>
  </div>
</template>

<script>
export default {
  name: 'AppfolioIntegration',
  components: {},
  props: {},
  setup(props, context) {
    return {};
  },
};
</script>
