<template>
  <ElDialog
    :center="true"
    top="30vh"
    :show-close="false"
    :visible="visible"
    :width="$viewport.lt.md ? '300px': '600px'"
    :title="getTitle(newRoleName)"
    @close="$emit('close')"
  >
    <div>
      <p>
        Are you sure you want to change the role of this user from {{ getFormattedRoleName(user.role) }} to
        {{ getFormattedRoleName(newRoleName) }}?
      </p>
      <div
        slot="footer"
        class="dialog-footer d-flex justify-content-center"
      >
        <ElButton
          size="medium"
          @click="$emit('close')"
        >
          Cancel
        </ElButton>
        <ElButton
          size="medium"
          type="danger"
          @click="$emit('changeRole')"
        >
          OK
        </ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import Role from '../../constants/Role';

export default {
  name: 'ChangeRoleDialog',
  props: {
    user: {
      type: Object,
      required: true,
    },
    newRoleName: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    return {
      getTitle,
      getFormattedRoleName,
    };

    function getTitle(roleName) {
      return `Change role to ${getFormattedRoleName(roleName)}`;
    }

    function getFormattedRoleName(roleName) {
      return (roleName === Role.ACCOUNT_OWNER) ? 'Account Owner' : 'Member';
    }
  },
};
</script>
