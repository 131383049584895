<template>
  <ElDialog
    id="connect-codebox-account-dialog"
    :center="true"
    :close-on-click-modal="false"
    :visible.sync="uiFlags.isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
  >
    <div v-if="uiFlags.fillDetailsStep">
      <div class="font-weight-strong font-21 text-center mb-3">
        Connect your Codebox Account
      </div>
      <div class="text-center my-3">
        By connecting Showdigs to your codebox account, you will be able
        to start self-showings at properties where you have active Codeboxes
        <br><br>
        Learn more about this integration
        <a
          href="https://help.showdigs.com/en/articles/5640284-how-to-enable-self-showings"
          target="_blank"
        >
          here
        </a>
      </div>
      <SdFloatLabel
        label="Username"
        class="mb-3"
      >
        <ElInput
          v-model="formRef.username"
          placeholder="Username"
        />
      </SdFloatLabel>
      <SdFloatLabel label="Password">
        <ElInput
          id="dontAskToSavePassword"
          v-model="formRef.password"
          class="dontAskToSavePassword"
          placeholder="Password"
        />
      </SdFloatLabel>
      <div
        v-if="uiFlags.apiError"
        class="mt-3 text-danger"
      >
        {{ uiFlags.apiError || 'An error occured' }}
      </div>
      <div class="text-center">
        <ElButton
          type="primary"
          size="medium"
          class="justify-center my-4"
          :loading="uiFlags.loading"
          :disabled="!formRef.password || !formRef.username"
          @click="connect"
        >
          Continue
        </ElButton>
      </div>
    </div>
    <div v-else>
      <div class="text-center justify-content-center px-5">
        <img
          :src="CompletedMarker"
          class="marker"
        >
        <div class="font-21 font-weight-strong my-4">
          Codebox account connected successfully
        </div>
        <div class="mb-2">
          {{ uiFlags.activeCodeboxes }} active {{ $pluralize('codeboxes', uiFlags.activeCodeboxes) }} found
        </div>
        <div>
          You can now activate self-showing properties
        </div>
        <ElButton
          type="primary"
          class="justify-center my-4"
          @click="uiFlags.isVisible = false;"
        >
          Done
        </ElButton>
        <div class="text-center" />
      </div>
    </div>
  </ElDialog>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import CompletedMarker from '@/assets/icons/list-mark.svg';

export default {
  name: 'ConnectCodeboxAccountDialog',
  setup(props, context) {
    const uiFlags = reactive({
      isVisible: false,
      loading: false,
      apiError: null,
      fillDetailsStep: true,
      activeCodeboxes: null,
    });
    const formRef = ref({
      username: null,
      password: null,
    });

    return {
      uiFlags,
      open,
      connect,
      formRef,
      CompletedMarker,
      setCodeBoxCount,
      stopLoading,
    };

    function open(data) {
      formRef.value.username = null;
      formRef.value.password = null;
      uiFlags.fillDetailsStep = true;
      uiFlags.isVisible = true;
    }

    function setCodeBoxCount(count) {
      uiFlags.activeCodeboxes = count;
      uiFlags.fillDetailsStep = false;
      uiFlags.loading = false;
    }

    function stopLoading(errorMessage = null) {
      uiFlags.loading = false;
      if (errorMessage) {
        uiFlags.apiError = errorMessage;
      }
    }

    async function connect() {
      const business = await context.root.$store.dispatch('Business/get');
      uiFlags.loading = true;
      uiFlags.apiError = null;
      try {
        const tokenData = await context.root.$store.dispatch('Codebox/register', {
          Username: formRef.value.username,
          Password: formRef.value.password,
        });
        context.emit('save-codebox-data', {
          codebox_token: tokenData.data.AuthToken,
        });
      } catch (error) {
         /* eslint-disable no-undef */
        if (process.env.VUE_APP_ENV !== 'production') {
          try {
            const tokenData = await context.root.$store.dispatch('Codebox/mockRegister', {
              businessId: business.id,
              username: formRef.value.username,
              password: formRef.value.password,
            });
            context.emit('save-codebox-data', {
              codebox_token: tokenData.AuthToken,
            });
          } catch (error) {
            uiFlags.apiError = error.response.data.message;
            uiFlags.loading = false;
          }
        } else {
          uiFlags.apiError = error.response.data;
          uiFlags.loading = false;
        }
         /* eslint-enable no-undef */
      }
    }
  },
};
</script>

<style lang="scss">
#connect-codebox-account-dialog {
  @include media-breakpoint-down(md) {
    height: 90vh;
    margin-top: 10vh;

    .el-dialog {
      height: 100%;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      border-top-left-radius: $app-border-radius-extra-large;
      border-top-right-radius: $app-border-radius-extra-large;

      > .el-dialog__body {
        flex: 1;
      }
    }
  }

  .dontAskToSavePassword {
    -webkit-text-security: disc;
  }

  .marker {
    height: 64px;
  }

}
</style>
