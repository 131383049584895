<template>
  <div>
    <div class="position-relative">
      <slot />
      <small
        v-if="isSaved"
        class="w-100 saved-indicator text-center line-height-1"
      >
        Saved
      </small>
    </div>
  </div>
</template>

<script>
import { watch } from '@vue/composition-api';

export default {
  name: 'SdSwitchSaved',
  props: {
    isSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, context) {
    let timeout = null;

    watch(() => props.isSaved, () => {
      if (!props.isSaved) {
        return;
      }

      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => context.emit('hide-text'), 3000);
    }, { immediate: true });
  },
};
</script>

<style lang="scss" scoped>
.saved-indicator {
  position: absolute;
  left: 0;
  bottom: 100%;
  color: theme-color('green');
}
</style>
