<template>
  <div class="row">
    <div class="col-12 col-md-10">
      <ImportPropertiesDialog
        v-if="uiFlags.isImportPropertiesDialogVisible"
        @close="importPropertiesDialogClosed"
      />
      <ConnectCodeboxAccountDialog
        ref="connectCodeboxAccountDialog"
        @save-codebox-data="saveCodeboxData"
      />
      <SelfShowingDisableDialog
        ref="selfShowingDisableDialog"
      />
      <BusinessTokenRevokeDialog
        ref="businessTokenRevokeDialog"
        @revoke-token="revokeToken"
      />
      <PMSIntegrationSettingsBlock :business-source="businessSource" />
      <div class="row no-gutters">
        <div
          v-if="businessSource.created_at && !businessSource.client_id"
          class="col-12"
        >
          <div class="row no-gutters justify-content-between mt-3">
            <h3 class="font-21">
              AppFolio Import (Legacy)
            </h3>
            <SdSwitchSaved
              :is-saved="uiFlags.autoSync"
              @hide-text="hideSwitchText('autoSync')"
            >
              <ElSwitch
                v-if="businessSourcePayloadRef"
                v-model="toggles.appfolioAutoSync"
                :disabled="toggles.appfolioAutoSync === null || !isOwner"
                class="col-auto d-block"
                :width="44"
                @change="onAppfolioIntegrationChange"
              />
            </SdSwitchSaved>
          </div>
          <div class="row no-gutters d-block">
            <p class="font-15 mb-2">
              Import and sync your current vacancies from AppFolio listing page directly to your Showdigs account.
            </p>
            <div
              v-if="businessSourcePayloadRef
                && businessSource.business_source_identifier !== null
                && businessSource.synced_at"
              class="col-12 text-gray-dark p-15"
            >
              Currently syncing vacancies from <strong>{{ businessSource.business_source_identifier }}.appfolio.com</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <ElDivider class="mt-4 mb-4" />
      </div>
      <div class="row no-gutters">
        <div class="col-12">
          <div class="row no-gutters justify-content-between">
            <h3 class="font-21">
              Codebox Integration
            </h3>
            <SdSwitchSaved
              :is-saved="uiFlags.codeboxIntegrationEnabled"
              @hide-text="hideSwitchText('codeboxIntegrationEnabled')"
            >
              <ElSwitch
                v-model="toggles.codeboxIntegrationEnabled"
                class="col-auto d-block"
                :disabled="!payload.codebox_connected || !isOwner"
                :width="44"
                @change="onDeviceIntegrationChange"
              />
            </SdSwitchSaved>
          </div>
          <div v-if="businessRef.subscription && !payloadRef.codebox_connected">
            <div>Enable self-showing tours by connecting your Codebox account</div>
            <ElAlert
              type="info"
              show-icon
              :closable="false"
              class="pb-3 mt-3"
            >
              <div>
                <p class="text-left font-weight-strong mb-0 mt-1">
                  Please note
                </p>
                <li>Self-showing tours are not available in occupied properties</li>
              </div>
            </ElAlert>
            <ElButton
              :disabled="!isOwner"
              type="primary"
              size="medium"
              class="my-3"
              @click="openCreateCodeboxAccountDialog"
            >
              Connect Codebox account
            </ElButton>
            <div class="mb-2">
              Don't have an acount?
              <a
                href="https://app.codeboxinc.com/register"
                target="_blank"
              >
                Click here
              </a>
            </div>
          </div>
          <div v-else>
            <div>
              {{ Object.keys(payload.devices.filter((device) => device.provider === codeboxProvider) || {}).length }} active
              {{ $pluralize("Codeboxes", Object.keys(payload.devices.filter((device) => device.provider === codeboxProvider) || {}).length) }}
              registered
            </div>
            <div class="mt-2">
              <router-link :to="{ name: 'app.devices' }">
                View all devices
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <ElDivider class="mt-4 mb-4" />
      </div>
      <div class="row no-gutters">
        <div class="col-12">
          <div class="row no-gutters justify-content-between">
            <h3 class="font-21">
              Account Access Token
            </h3>
          </div>
          <div class="row no-gutters d-block">
            <p class="font-15 mb-2">
              Generate and manage an access token for connecting to the Showdigs API.
            </p>
          </div>
          <BusinessSettingsTokenField
            v-if="token && (token.tokenCreatedAt || token.tokenValue)"
            :token="token"
            @open-revoke-token-dialog="openRevokeTokenDialog"
          />
          <div
            v-else
            class="col-12 d-flex justify-content-end my-3"
          >
            <ElButton
              type="primary"
              size="medium"
              :loading="uiFlags.isTokenGenerating"
              @click="generateAccessToken"
            >
              Generate Access Token
            </ElButton>
          </div>
          <ElDivider class="mt-4 mb-4" />
          <div class="row no-gutters justify-content-between">
            <h3 class="font-21">
              Webhook integration
            </h3>
          </div>
          <div class="row no-gutters d-block">
            <p class="font-15 mb-2">
              A URL Showdigs can use to update when a prospect schedules or completes a tour.<br>
              Check out <a
                href="https://api.showdigs.com/docs/#webhook-integration"
                target="_blank"
              >webhook documentation</a>
              for more details.
            </p>
          </div>
          <ElForm
            ref="webhookIntegrationForm"
            class="mt-1"
            :model="webhookIntegration"
            @submit.native.prevent="integrateWebhook"
          >
            <div class="col-12 pl-0 no-gutters d-block">
              <SdFloatLabel for-input="webhook_integration_url">
                <ElFormItem class="mb-4">
                  <ElInput
                    v-model="webhookIntegration.url"
                    prop="webhook_integration_url"
                    placeholder="Webhook URL"
                    @change="webhookUrlChanged"
                  />
                </ElFormItem>
              </SdFloatLabel>
              <SdFloatLabel for-input="webhook_integration_token">
                <ElFormItem class="mb-4">
                  <ElInput
                    v-model="webhookIntegration.token"
                    prop="webhook_integration_token"
                    placeholder="Webhook access token (optional)"
                  />
                </ElFormItem>
              </SdFloatLabel>
            </div>
            <div class="col-12 d-flex justify-content-end my-3">
              <ElButton
                type="primary"
                size="medium"
                :loading="uiFlags.isWebhookIntegrating"
                :disabled="!webhookChanged"
                @click.native="integrateWebhook"
              >
                Update
              </ElButton>
            </div>
          </ElForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
 computed, reactive, ref, watch,
} from '@vue/composition-api';
import SdSwitchSaved from '@/components/common/form/SdSwitchSaved';
import ConnectCodeboxAccountDialog from '@/pages/App/Profile/ConnectCodeboxAccountDialog';
import SelfShowingDisableDialog from '@/pages/App/Profile/SelfShowingDisableDialog.vue';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import get from 'lodash.get';
import Regex from '@/constants/Regex';
import BusinessTokenRevokeDialog from '@/pages/App/Profile/BusinessTokenRevokeDialog.vue';
import ImportPropertiesDialog from '@/components/property/ImportPropertiesDialog.vue';
import { DEVICE_PROVIDER } from '@/constants/Device';
import BusinessSettingsTokenField from './BusinessSettingsTokenField.vue';
import PMSIntegrationSettingsBlock from './PMSIntegrationSettingsBlock.vue';

const regexConstants = Regex;

export default {
  name: 'BusinessSettingsIntegrationTab',
  components: {
    SdSwitchSaved,
    ConnectCodeboxAccountDialog,
    ImportPropertiesDialog,
    SelfShowingDisableDialog,
    BusinessSettingsTokenField,
    BusinessTokenRevokeDialog,
    PMSIntegrationSettingsBlock,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
    business: {
      type: Object,
      default: () => {},
    },
    businessSource: {
      type: Object,
      default: () => {},
    },
    businessSourcePayload: {
      type: Object,
      default: () => {},
    },
    isOwner: {
      type: Boolean,
      default: () => {},
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const payloadRef = ref(props.payload);
    const businessRef = ref(props.business);
    const businessSourcePayloadRef = ref(props.businessSourcePayload);
    const codeboxProvider = DEVICE_PROVIDER.CODEBOX;
    const webhookIntegration = reactive({
      ...(props.business.webhook_integration || {
        url: null,
        token: null,
      }),
    });
    const webhookChanged = computed(computeWebhookChanged);
    const token = reactive({
      tokenCreatedAt: businessRef.value.token_created_at?.created_at,
      tokenValue: null,
    });
    const toggles = reactive({
      appfolioAutoSync: businessSourcePayloadRef.value.auto_sync,
      codeboxIntegrationEnabled: payloadRef.value.codebox_connected,
    });
    const uiFlags = reactive({
      autoSync: false,
      codeboxIntegrationEnabled: false,
      isImportPropertiesDialogVisible: false,
      isTokenGenerating: false,
      isWebhookIntegrating: false,
    });

    if (payloadRef.value.codebox_connected) {
      try {
        loadCodeboxes();
      } catch (error) {}
    }
    watch(
      () => props.payload,
      () => {
        payloadRef.value = props.payload;
        toggles.codeboxIntegrationEnabled = payloadRef.value.codebox_connected;
      },
    );
    store.subscribe((mutation) => {
      if (mutation.type.includes('BusinessSettings/')) {
        payloadRef.value = props.payload;
        toggles.codeboxIntegrationEnabled = payloadRef.value.codebox_connected;
      }
    });

    return {
      payloadRef,
      businessRef,
      businessSourcePayloadRef,
      uiFlags,
      toggles,
      openCreateCodeboxAccountDialog,
      importPropertiesDialogClosed,
      saveCodeboxData,
      hideSwitchText,
      onAppfolioIntegrationChange,
      onDeviceIntegrationChange,
      generateAccessToken,
      integrateWebhook,
      webhookIntegration,
      webhookChanged,
      webhookUrlChanged,
      openRevokeTokenDialog,
      token,
      revokeToken,
      codeboxProvider,
    };

    function computeWebhookChanged() {
      const integrationEnabled = !!businessRef.value.webhook_integration;

      if (integrationEnabled) {
        const valuesChanged = webhookIntegration.token !== businessRef.value.webhook_integration.token
          || webhookIntegration.url !== businessRef.value.webhook_integration.url;
        return valuesChanged;
      }

      return webhookIntegration.url;
    }

    function webhookUrlChanged() {
      if (webhookIntegration.url && !webhookIntegration.url.match(regexConstants.http_protocol)) {
        webhookIntegration.url = `https://${webhookIntegration.url}`;
      }
    }

    async function integrateWebhook() {
      uiFlags.isWebhookIntegrating = true;
      try {
        if (!webhookIntegration.url) {
          webhookIntegration.token = null;
        }
        const business = await store.dispatch('Business/updateWebhookIntegration', {
          url: webhookIntegration.url,
          token: webhookIntegration.token,
        });
        businessRef.value = business;
        showSuccessNotify(context, 'Webhook integration updated');
      } catch (error) {
        showErrorNotify(context, error.message || 'Something went wrong, try again');
      }
      uiFlags.isWebhookIntegrating = false;
    }

    async function generateAccessToken() {
      uiFlags.isTokenGenerating = true;
      token.tokenValue = await store.dispatch('Business/generateToken');
      uiFlags.isTokenGenerating = false;
    }

    function openRevokeTokenDialog() {
      context.refs.businessTokenRevokeDialog.open();
    }

    function revokeToken() {
      token.tokenCreatedAt = null;
      token.tokenValue = null;
    }

    function openCreateCodeboxAccountDialog() {
      context.refs.connectCodeboxAccountDialog.open();
    }

    async function importPropertiesDialogClosed(data) {
      uiFlags.isImportPropertiesDialogVisible = false;

      if (data && data.status === 'success') {
        showSuccessNotify(context, 'Successfully synced with your listings page');
        context.emit('init-business-source');
      } else {
        toggles.appfolioAutoSync = false;
      }

      await onToggleChange('auto_sync', 'autoSync');
    }

    async function loadCodeboxes() {
      payloadRef.value.devices = await store.dispatch('Device/get');
    }

    async function saveCodeboxData(data) {
      await store.dispatch('BusinessSettings/updateAndCommitPayload', {
        meta: 'device_integration_enabled',
        payload: {
          ...data,
          device_integration_enabled: true,
        },
      });

      try {
        await loadCodeboxes();
        // eslint-disable-next-line max-len
        context.refs.connectCodeboxAccountDialog.setCodeBoxCount(Object.keys(payloadRef.value.devices.filter((device) => device.provider === codeboxProvider) || {}).length);
        delete payloadRef.value.codebox_token;
        context.emit('init');
      } catch (error) {
        const defaultMessage = 'There was an error activating your codebox. Please try again or contact us for support';
        context.refs.connectCodeboxAccountDialog.stopLoading(error.message || defaultMessage);
      }
    }

    function hideSwitchText(key) {
      uiFlags[key] = false;
    }

    async function onAppfolioIntegrationChange(isSwitchOn) {
      if (isSwitchOn) {
        uiFlags.isImportPropertiesDialogVisible = true;
      }

      await onToggleChange('auto_sync', 'autoSync');
    }

    async function onDeviceIntegrationChange() {
      if (!toggles.codeboxIntegrationEnabled) {
        const devices = await context.root.$store.dispatch('Device/get');
        const activeDevices = devices.filter((device) => !get(device.showing_profile, 'deleted_at', true));
        if (activeDevices.length) {
          const activeDevicesProperties = activeDevices.map((activeDevice) => ({
            address: activeDevice.showing_profile.unit.property.short_address,
          }));
          context.refs.selfShowingDisableDialog.open(activeDevicesProperties);
          toggles.codeboxIntegrationEnabled = true;
          return;
        }
      }

      await onToggleChange('device_integration_enabled', 'codeboxIntegrationEnabled');
    }

    async function onToggleChange(key, uiFlagKey) {
      const params = {};
      if (key === 'device_integration_enabled') {
        params[key] = toggles.codeboxIntegrationEnabled;

        if (toggles.codeboxIntegrationEnabled === false) {
          payloadRef.value.codebox_token = null;
          payloadRef.value.codebox_connected = false;
        }

        params.codebox_token = payloadRef.value.codebox_token;
        params.codebox_connected = payloadRef.value.codebox_connected;
        try {
          await store.dispatch('BusinessSettings/updateAndCommitPayload', {
            meta: key,
            payload: params,
          });
        } catch (error) {
          toggles.codeboxIntegrationEnabled = true;
          params.codebox_connected = true;
          payloadRef.value.codebox_connected = true;
          showErrorNotify(context, error.message);
        }
      } else {
        context.emit('update-business-source', {
          auto_sync: toggles.appfolioAutoSync,
        });
      }

      uiFlags[uiFlagKey] = true;
    }
  },
};
</script>
