<template>
  <div class="col-12 col-md-12">
    <ElForm
      ref="form"
      :model="payloadRef"
      :rules="rules"
      class="row align-items-center"
      @submit.native.prevent="updateScreeningCriteria"
    >
      <div class="col-12">
        <ElFormItem
          prop="title"
        >
          <SdFloatLabel>
            <ElInput
              v-model="payloadRef.title"
              placeholder="Template name"
            />
          </SdFloatLabel>
        </ElFormItem>
      </div>
      <div class="col-12">
        <ShowingProfilePropertyScreening
          :screening-criteria="payloadRef.property_screening"
          :disabled="false"
          @change="updateDefaultScreeningCriteria"
        />
      </div>
      <div class="col-12">
        <ShowingProfileUnitScreening
          :screening-criteria="payloadRef.showing_screening"
          :custom-screening="payloadRef.custom_screening"
          :listing-data="{}"
          :show-max-allowed-tenants="false"
          @change="additional_criteria => updateScreeningPayload(
            { ...payloadRef.screening_criteria, ...additional_criteria },
            'screening_criteria'
          )"
          @custom-question-update="updateCustomQuestionPayload"
        />
      </div>
      <div class="col-12 d-flex justify-content-end">
        <ElButton
          :size="$viewport.gt.md ? 'medium' : 'small'"
          :loading="uiFlags.preScreeningFormLoading"
          @click="$emit('discard')"
        >
          Discard
        </ElButton>
        <ElButton
          type="primary"
          :size="$viewport.gt.md ? 'medium' : 'small'"
          :loading="uiFlags.preScreeningFormLoading"
          @click="updateScreeningCriteria"
        >
          Update
        </ElButton>
      </div>
    </ElForm>
  </div>
</template>

<script>

import SdFloatLabel from '@/components/common/form/SdFloatLabel.vue';
import ShowingProfilePropertyScreening from '@/components/showing-profile/ShowingProfilePropertyScreening.vue';
import { reactive, ref } from '@vue/composition-api';
import regex from '@/constants/Regex';
import ShowingProfileUnitScreening from '@/components/showing-profile/ShowingProfileUnitScreening.vue';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';

const regexConstants = regex;

export default {
  name: 'CreateTemplateForm',
  components: {
    ShowingProfileUnitScreening,
    ShowingProfilePropertyScreening,
    SdFloatLabel,
  },
  props: {
    templateData: {
      type: Object,
      required: false,
      default: null,
    },
    order: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props, context) {
    const { $store } = context.root;

    const payloadRef = ref({
      title: props.templateData?.title,
      property_screening: props.templateData?.property_screening,
      showing_screening: props.templateData?.showing_screening,
      custom_screening: props.templateData?.custom_screening ?? [],
      id: props.templateData?.id ?? null,
      order: props.templateData?.order ?? props.order,
      enabled: true,
      uuid: props.templateData?.uuid,
    });
    const rules = ref(getFormRules());
    const uiFlags = reactive({
      preScreeningFormLoading: false,
    });

    const isEditFlow = ref(!!props.templateData?.title);

    return {
      payloadRef,
      rules,
      updateDefaultScreeningCriteria,
      updateScreeningCriteria,
      uiFlags,
      updateScreeningPayload,
      addCustomQuestion,
      removeCustomQuestionsFromArray,
      updateCustomQuestionPayload,
    };

    function addCustomQuestion() {
      payloadRef.value.custom_screening.push({});
    }

    function removeCustomQuestionsFromArray(questionIndex) {
      payloadRef.value.custom_screening = payloadRef.value.custom_screening.filter((value, index) => index !== questionIndex);
    }

    async function updateScreeningCriteria() {
      try {
        await context.refs.form.validate();
        let message;
        if (!isEditFlow.value) {
          const result = await $store.dispatch('ScreeningTemplates/create', payloadRef.value);
          payloadRef.value.id = result.id;
          payloadRef.value.uuid = result.uuid;
          message = 'Screening template created successfully';
        } else {
          const result = await $store.dispatch('ScreeningTemplates/update', payloadRef.value);
          message = 'Screening template updated successfully';
        }
        showSuccessNotify(context, message);
        context.emit('template-added', { payload: payloadRef.value, isEdit: isEditFlow.value });
      } catch (error) {
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      }
    }

    async function updateDefaultScreeningCriteria(screeningCriteria) {
      payloadRef.value.property_screening = screeningCriteria;
    }

    function updateScreeningPayload(screeningCriteria, key) {
      payloadRef.value.showing_screening = screeningCriteria;
    }

    function updateCustomQuestionPayload(customQuestion) {
      payloadRef.value.custom_screening = customQuestion;
    }

    function getFormRules() {
      return {
        title: [
          {
            required: true,
            type: 'string',
            message: 'Template name is required',
          },
        ],
      };
    }
  },
};
</script>

<style lang="scss">
</style>
