<template>
  <div class="row no-gutters">
    <PMSIntegrationSettingsDialog
      v-if="openPMSIntegrationDialog"
      @close="value =>closePMSIntegrationDialog(value)"
    />
    <PMSIntegrationSettingDisconnectDialog
      v-if="openPMSIntegrationDisconnectDialog"
      :business-source="currentBusinessSource"
      @close="value =>closePMSIntegrationDisconnectDialog(value)"
    />
    <div class="col-12">
      <div class="row no-gutters justify-content-between">
        <h3 class="font-21">
          PMS Integration
        </h3>
        <SdSwitchSaved
          :is-saved="true"
        >
          <ElSwitch
            v-model="PMSIntegration"
            class="col-auto d-block"
            :width="44"
            :disabled="isAppfolio"
            @change="togglePMSIntegrationButton"
          />
        </SdSwitchSaved>
      </div>
      <div class="row no-gutters d-block">
        <p class="font-15 mb-0">
          Connect your PMS system to supercharge your workflow.
          <a
            target="_blank"
            :href="`https://help.showdigs.com/kb/guide/en/pms-integration-101-WlfHYTK22C`"
          >Learn More </a>
        </p>
      </div>
      <ElAlert
        v-if="PMSIntegration || lastBusinessSourceError"
        :type="uiFlags.alertType"
        :closable="false"
        class="mt-3"
      >
        <div>
          {{ integrationInfoText }}
        </div>
      </ElAlert>
      <div
        v-if="PMSIntegration && isAppfolio"
        class="row no-gutters justify-content-between mt-3"
      >
        <SdFloatLabel class="col-12 col-md-6">
          <ElSelect
            v-model="AppfolioConditionReportUploadOptionSelected"
            placeholder="Condition report upload"
            @change="changeAppfolioConditionReportUploadOptions"
          >
            <ElOption
              v-for="option in AppfolioConditionReportUploadOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </ElSelect>
        </SdFloatLabel>
        <!-- eslint-disable vue/no-v-html -->
        <p
          class="text-left m-1 font-15 text-gray-dark"
          v-html="selectedOptionObject.description"
        />
        <!-- eslint-enable -->
      </div>
    </div>
  </div>
</template>

<script>

import { AppfolioConditionReportUploadOptions, AppfolioConditionReportUploadTypes } from '@/constants/Integration';
import { reactive, ref } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import SdSwitchSaved from '@/components/common/form/SdSwitchSaved.vue';
import PMSIntegrationSettingsDialog from '@/components/settings/PMSIntegrationSettingsDialog.vue';
import { BusinessSourceTypes } from '@/constants/BusinessSource';
import momentUtil from '@/utils/MomentUtil';
import { capitalizeFirstLatter } from '@/utils/StringUtil';
import PMSIntegrationSettingDisconnectDialog from '@/components/settings/PMSIntegrationSettingDisconnectDialog.vue';

export default {
  name: 'PMSIntegrationSettingsBlock',
  components: {
    PMSIntegrationSettingDisconnectDialog,
    PMSIntegrationSettingsDialog,
    SdSwitchSaved,
  },
  props: {
    businessSource: {
      type: Object,
      default: null,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const uiFlags = reactive({
      alertType: 'info',
    });
    const syncOption = props.businessSource.sync_inspection_reports;
    const lastBusinessSource = store.getters['Auth/lastBusinessSource'];
    const lastBusinessSourceError = lastBusinessSource?.error;
    const selectedOptionObject = ref(getOptionObjectByValue(syncOption || AppfolioConditionReportUploadTypes.TYPE_AUTOMATIC));
    const AppfolioConditionReportUploadOptionSelected = ref(selectedOptionObject.value.value);
    const openPMSIntegrationDialog = ref(false);
    const openPMSIntegrationDisconnectDialog = ref(false);
    const sourceType = ref(props.businessSource.source_type);
    const isAppfolio = sourceType.value === BusinessSourceTypes.APPFOLIO;
    const currentBusinessSource = ref(props.businessSource);
    const PMSIntegration = ref(resolvePMSIntegration());
    const integrationInfoText = ref(resolveIntegrationInfoText());

    return {
      uiFlags,
      selectedOptionObject,
      AppfolioConditionReportUploadOptions,
      AppfolioConditionReportUploadOptionSelected,
      changeAppfolioConditionReportUploadOptions,
      openPMSIntegrationDialog,
      isAppfolio,
      togglePMSIntegrationButton,
      PMSIntegration,
      integrationInfoText,
      closePMSIntegrationDialog,
      openPMSIntegrationDisconnectDialog,
      currentBusinessSource,
      closePMSIntegrationDisconnectDialog,
      lastBusinessSource,
      lastBusinessSourceError,
    };

    function closePMSIntegrationDisconnectDialog(value) {
      openPMSIntegrationDisconnectDialog.value = false;
      PMSIntegration.value = value;
    }

    function closePMSIntegrationDialog(newBusinessSource) {
      PMSIntegration.value = !!newBusinessSource.id;
      if (PMSIntegration.value) {
        sourceType.value = newBusinessSource?.source_type;
        currentBusinessSource.value = newBusinessSource;
        integrationInfoText.value = resolveIntegrationInfoText();
      }
      openPMSIntegrationDialog.value = false;
    }
    function resolveIntegrationInfoText() {
      if (!sourceType.value && lastBusinessSource?.error) {
        const source = capitalizeFirstLatter(lastBusinessSource.source_type);
        return getPMSIntegrationError(source);
      }

      if (!sourceType.value) {
        return false;
      }

      uiFlags.alertType = 'info';
      const source = capitalizeFirstLatter(sourceType.value);
      if (sourceType.value === BusinessSourceTypes.APPFOLIO) {
        const connectionDate = momentUtil(props.businessSource.created_at, momentUtil.tz.guess());
        return `Connected to AppFolio on ${connectionDate.toDisplayFormatFullMonthDayAndYearSt()}`;
      }

      if (currentBusinessSource.value.synced_at) {
        const connectionDate = momentUtil(props.businessSource.synced_at, momentUtil.tz.guess());
        return `Connected to ${source} on ${connectionDate.toDisplayFormatFullMonthDayAndYearSt()}`;
      }

      if (props.businessSource.error) {
        return getPMSIntegrationError(source);
      }

      return `We are establishing the initial connection to ${source}, which may take up to an hour to complete.  No further action is required, and you can navigate away from this page while the system is connecting.
      You will receive an email once the first synchronization cycle has been completed.`;
    }

    function getPMSIntegrationError(source) {
      uiFlags.alertType = 'error';
      return `Error with ${source} connection`;
    }

    function resolvePMSIntegration() {
      return Boolean(props.businessSource.propexo_integration_id || props.businessSource.client_id);
    }

    function togglePMSIntegrationButton() {
      if (PMSIntegration.value) {
        openPMSIntegrationDialog.value = true;
      } else {
        openPMSIntegrationDisconnectDialog.value = true;
      }
    }

    async function changeAppfolioConditionReportUploadOptions() {
      const previousValue = selectedOptionObject.value.value;

      try {
        await store.dispatch('BusinessSource/update', {
          sync_inspection_reports: AppfolioConditionReportUploadOptionSelected.value,
        });
        selectedOptionObject.value = getOptionObjectByValue(AppfolioConditionReportUploadOptionSelected.value);
        showSuccessNotify(context, `${selectedOptionObject.value.label} option has been selected`);
      } catch (e) {
        AppfolioConditionReportUploadOptionSelected.value = previousValue;

        showErrorNotify(context, 'Something went wrong. Please try again');
      }
    }

    function getOptionObjectByValue(value) {
      return AppfolioConditionReportUploadOptions.find((option) => option.value === value);
    }
  },
};
</script>

<style scoped>
.el-spinner {
  vertical-align: top;
}
</style>
