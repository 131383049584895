<template>
  <div class="identity-verification-container">
    <div class="d-flex justify-content-between">
      <h3 class="font-21">
        <i class="sdicon-fingerprint mr-1 font-21" />
        AI Identity Verification default
      </h3>
    </div>
    <p class="font-15 mt-2">
      Protect yourself from scammers by using AI-powered facial recognition and ID
      verification to verify prospects before they can schedule a tour. Set your
      default value here. You will be able to make changes for individual properties
      when starting showings.
    </p>
    <div
      class="d-flex mt-2 mx-md-3 text-gray-light"
      :class="$viewport.lt.md ?'justify-content-center' :'justify-content-sm-between'"
    >
      <AddOnArt
        art="ai-facial"
        size="44px"
        description="AI facial recognition"
      />
      <AddOnArt
        art="id"
        size="56px"
        height="44px"
        description="ID verification"
      />
      <AddOnArt
        art="ai-age"
        size="56px"
        height="44px"
        description="AI age validation"
      />
    </div>
    <p class="font-15 mt-3">
      When enabled for an active property, you will be charged $2 per verification.
      <a
        class="d-block"
        target="_blank"
        href="https://help.showdigs.com/kb/guide/en/ai-identity-verification-UwMZpc3Dp3/Steps/2436526"
      >Learn more</a>
    </p>
    <div class="my-4">
      <div class="d-flex justify-content-center">
        <ElSelect
          v-model="identityVerification"
          value-key="name"
          class="col-auto d-block px-0"
          :class="$viewport.gt.md ? 'w-100' : 'w-75'"
        >
          <ElOption
            v-for="option in IdentityVerificationOptions"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </ElSelect>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-end mt-4">
      <ElButton
        type="primary"
        :size="$viewport.gt.md ? 'medium' : 'small'"
        :loading="uiFlags.loading"
        @click="update"
      >
        Update
      </ElButton>
    </div>
  </div>
</template>
<script>

import AddOnArt from '@/components/settings/AddOnArt.vue';
import { IdentityVerificationOptions, IdentityVerificationTypes } from '@/constants/Business';
import { reactive, ref } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';

export default {
  name: 'BusinessSettingsShowingsAIIdentity',
  components: {
    AddOnArt,
  },
  props: {},
  setup(props, context) {
    const store = context.root.$store;
    const business = context.root.$store.getters['Auth/business'];
    const identityVerification = ref(business.identity_verification);

    const uiFlags = reactive({
      loading: false,
    });

    return {
      identityVerification,
      uiFlags,
      update,
      IdentityVerificationOptions,
    };

    async function update() {
      uiFlags.loading = true;
      try {
        await store.dispatch('BusinessSettings/updateAndCommitPayload', {
          payload: { identity_verification: identityVerification.value },
          meta: 'identity_verification',
        });
        store.commit('Auth/setBusiness', { identity_verification: identityVerification.value });
        showSuccessNotify(context, getRelevantSuccessMessage(), { duration: 8000 });
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.loading = false;
      }
    }

    function getRelevantSuccessMessage() {
      if (identityVerification.value === null) {
        return 'AI Identity Verification will be disabled by default for new unit activations, You can manually enable it during activation if needed.';
      }

      const typeText = identityVerification.value === IdentityVerificationTypes.TYPE_ALL ? '' : ' self showing';
      return `AI Identity Verification will be enabled by default for new ${typeText} activations, You can manually disable it during activation if needed.`;
    }
  },
};
</script>
<style scoped lang="scss">

</style>
